import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { CustomGridInfo } from 'src/app/models/custom-grid-info';

@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent implements OnInit {

  @Input() DataSourceName!: string;
  @Input() GridTitle: string = "";
  @Input() AllowPaging: boolean = true;
  @Input() SearchParams: any | null = null;
  @Input() RecordCount: number = 100;
  @Input() selectable: string | null = null;
  @Input() showPointer: boolean = false;
  @Output() onSelect = new EventEmitter<any>();

  searchFilter: string = "";
  loading = true;
  ids: ActivatedIds = {};
  customerGuid: string | undefined = "";

  info: CustomGridInfo | null = null;
  data: any[] = [];
  first: number = 0;

  constructor(private main: MainService, 
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private apiService: ApiService) { }
  
  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.customerGuid = paramMap.get('customerGuid')?.toString();
      this.customerService.initCustomer(this.customerGuid);
      this.main.activatedIds$.subscribe((ids) => {
        if (ids && ids.targetGuid){
          if (!this.ids || this.ids.targetGuid !== ids.targetGuid){
            this.ids = ids;
          }
        }
      }, (err) => {console.error(err)});
      this.getDataInfo();
    });
  }

  getDataInfo(){
    let body = {
      "customerGuid": this.customerGuid,
      "campaignGuid": this.ids.campaignGuid,
      "targetGuid": this.ids.targetGuid,
      "dataSourceName": this.DataSourceName,
      "remoteIPAddress": ""
    };

    this.apiService.post(`dataGrid/grid-info`, body)
    .subscribe((res: any) => {
      this.info = res;
      this.getDataFromSource();
    }, (err: any) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  getDataFromSource(){

    let body = {
      "customerGuid": this.customerGuid,
      "campaignGuid": this.ids.campaignGuid,
      "targetGuid": this.ids.targetGuid,
      "dataSourceName": this.DataSourceName,
      "remoteIPAddress": "",
      "columnList": this.info!.columnNames.join(','),
      "pageSize": 3000
    };

    this.apiService.post(`dataGrid/grid-data`, body)
    .subscribe((res: any) => {      
      this.data = res;
      this.loading = false;
    }, (err: any) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  rowSelected(row: any){
    this.onSelect.emit(row);
  }
}
