import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetInfoModel } from 'src/app/models/target-info-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, 
    private customerService: CustomerService, 
    private mainService: MainService, 
    private userService: UserService,
    private apiService: ApiService, 
    private sanitizer: DomSanitizer) { }

  @ViewChild('campaignFrame') campaignFrame: any;

  ids: ActivatedIds = {};
  targetInfo: TargetInfoModel | null = null;
  iframeUrl: string | SafeHtml | null = "";
  pages: any[] = [];
  selectedPage: any = {};

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      var customerGuid = paramMap.get('customerGuid')?.toString();
      this.customerService.initCustomer(customerGuid);
    });

    this.mainService.activatedIds$.subscribe(ids => {
      this.ids = ids;

      this.apiService.get(`csr/campaign-pages/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe(r => {
        this.pages = r;

        this.apiService.get(`csr/campaign-target-info/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: any) => {
          this.targetInfo = res;
          this.selectedPage = this.pages[0];
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://${this.targetInfo?.campaignDomain}/campaign/${this.targetInfo?.campaignFolder}/${this.selectedPage.pageUrl}?tGuid=${this.ids.targetGuid}&cGuid=${this.ids.campaignGuid}&csr_mode=true`);         
        });
      });
    });

    window.addEventListener("message", (e) => {            
      var eventName = e.data[0];
      var data = e.data[1];
      switch (eventName) {
          case 'setHeight':
              this.campaignFrame.nativeElement.style.height = data + 'px';
              break;
      }}
    , false);
  }

  iFrameLoaded(event: Event){
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  pageSelected(event: any){
    this.selectedPage = event;
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://${this.targetInfo?.campaignDomain}/campaign/${this.targetInfo?.campaignFolder}/${this.selectedPage.pageUrl}?tGuid=${this.ids.targetGuid}&cGuid=${this.ids.campaignGuid}&csr_mode=true`);
  }
  
}
