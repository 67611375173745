<div class="cds-card">
    <div class="d-flex p-3">
        <div class="p-1 flex-fill">
            <p class="lead">Customer Name</p>
            <p class="m-0 h6">{{targetInfo.fullName}}</p>
        </div>
        <div class="p-1 flex-fill">
            <div class="clickable" placement="right"
            [ngbPopover]="popContent" popoverTitle="">
                <p class="lead">Account Number</p>
                <p class="m-0 h6">{{targetInfo.loanNumber}}</p>
            </div>
            <ng-template #popContent>
                <p class="m-0" *ngFor="let p of popItems">{{p}}</p>
            </ng-template>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS') || hasFeature('CSR_CUSTOMER_READ_PAY_STATUS', 'read')" class="p-1 flex-fill">
            <p class="lead">Pay Status</p>
            <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS')" class="h6">
                <covered-button [matMenuTriggerFor]="changePayStatus" class="p-button-text p-button-plain cds-font cds-text-color">
                    <span class="">{{targetInfo.paymentMethod}}</span>
                    <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
                </covered-button>
                  <mat-menu #changePayStatus="matMenu">
                    <button mat-menu-item *ngFor="let opt of payMethods" (click)="payStatusChange(opt)">
                        {{opt.desc}}
                    </button>
                  </mat-menu>
            </p>
            <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_PAY_STATUS')" class="m-0 h6">{{targetInfo.paymentMethod}}</p>

        </div>
        <div *ngIf="targetInfo.showCSRFundingBankAccount == 'True'" class="p-1 flex-fill">
            <p class="lead">Funding Type</p>
            <p class="m-0 h6">{{targetInfo.fundingType}}</p>

        </div>
        <div class="p-1 flex-fill">
            <p class="lead">DPD</p>
            <p class="m-0 h6">
                <span *ngIf="targetInfo.daysPastDue === '0'">ZERO</span>
                <span *ngIf="targetInfo.daysPastDue != '0'">{{targetInfo.daysPastDue}}</span>
            </p>

        </div>
        <div class="p-1 flex-fill">
            <p class="lead">Application Status</p>
            <p class="m-0 h6">{{targetInfo.loanAppStatus}}</p>

        </div>
        <div class="p-1 flex-fill">
            <p class="lead">Contract Status</p>
            <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_CONTRACT_STATUS')" class="m-0 h6">
                <covered-button [matMenuTriggerFor]="changeContractStatus" class="p-button-text p-button-plain cds-font text-color">
                    <span class="me-1">{{targetInfo.loanStatus}}</span>
                    <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                    <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
                  </covered-button>
                  <mat-menu #changeContractStatus="matMenu">
                    <button mat-menu-item *ngFor="let opt of loanStatuses" (click)="cantractStatusChange(opt)">
                        {{opt.desc}}
                    </button>
                  </mat-menu>
            </p>
            <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_CONTRACT_STATUS')" class="m-0 h6">{{targetInfo.loanStatus}}</p>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_READ_LEGAL_STATUS', 'read') || hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS')" class="p-1 flex-fill">
            <p class="lead">Legal Status</p>
            <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS')" class="m-0 h6">
                <covered-button [matMenuTriggerFor]="changeLegalStatus" class="p-button-text p-button-plain cds-font cds-text-color">
                    <span class="me-1">{{targetInfo.legalStatus}}</span>
                    <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                    <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
                  </covered-button>
                  <mat-menu #changeLegalStatus="matMenu">
                    <button mat-menu-item *ngFor="let opt of legalStatusOptions" (click)="legalStatusChange(opt)">
                        {{opt.desc}}
                    </button>
                  </mat-menu>
            </p>
            <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_LEGAL_STATUS')" class="m-0 h6">{{targetInfo.legalStatus}}</p>

        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_READ_CONCESSION', 'read') || hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="p-1 flex-fill">
            <p class="lead">Concession</p>
            <p *ngIf="hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="m-0 h6">
                <covered-button [matMenuTriggerFor]="changeConcession" class="p-button-text p-button-plain cds-font cds-text-color">
                    <span *ngIf="targetInfo.isConcession == 'False'" class="me-1">OFF</span>
                    <span *ngIf="targetInfo.isConcession == 'True'" class="me-1">ON</span>
                    <!-- <mat-icon>keyboard_arrow_down</mat-icon> -->
                    <i class="pi pi-chevron-down pl-2" aria-hidden="true"></i>
                  </covered-button>
                  <mat-menu #changeConcession="matMenu">
                    <button mat-menu-item (click)="concessionChange()">
                        <span *ngIf="targetInfo.isConcession == 'False'">ON</span>
                        <span *ngIf="targetInfo.isConcession == 'True'">YES</span>
                    </button>
                  </mat-menu>
            </p>
            <p *ngIf="!hasFeature('CSR_CUSTOMER_CHANGE_CONCESSION')" class="m-0 h6">
                <span *ngIf="targetInfo.isConcession == 'False'">OFF</span>
                <span *ngIf="targetInfo.isConcession == 'True'">ON</span>
            </p>
        </div>
        <div *ngIf="targetInfo.cancelShow === 'True'" class="p-1 pr-5 pt-4">
            <covered-button color="primary" (click)="cancelShow()">Cancel</covered-button>
        </div>
        <div *ngIf="targetInfo.withdrawShow == 'True'" class="p-1 pr-5 pt-4">
            <covered-button color="primary" (click)="withdrawShow()">Withdraw</covered-button>
        </div>
      </div>
    </div>