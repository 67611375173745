<div class="pay-height">

    <p-table [value]="payments" styleClass="p-datatable-lg mr-4" [rowHover]="true"
    [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" 
    [showCurrentPageReport]="false" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th class="font-medium" scope="col">Due Date</th>
            <th class="font-medium" scope="col">Payment No.</th>
            <th class="font-medium" scope="col">Amount Due</th>
            <th class="font-medium" scope="col">Principal</th>
            <th class="font-medium" scope="col">Finance Charges</th>
            <th class="font-medium" scope="col">Fees</th>
            <th class="font-medium" scope="col">Balance</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-h>
        <tr class="cursor-pointer">
            <td>{{ h.paymentDate }}</td>
            <td>{{ h.paymentNumber }}</td>
            <td>{{ h.paymentAmount }}</td>
            <td>{{ h.principalAmount }}</td>
            <td>{{ h.interestAmount }}</td>
            <td>{{ h.otherAmount }}</td>
            <td>{{ h.balanceAmount }}</td>
        </tr>
    </ng-template>
</p-table>

<p class="text-center py-3">
    <covered-button type="button" (click)="btnClick.emit()">OKAY</covered-button>
</p>
</div>