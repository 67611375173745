<app-dashboard-header></app-dashboard-header>
<p class="text-2xl font-bold m-2">Customer Communications</p>
<div class="flex">
    <div class="md:col-8 col-12">
        <div class="cds-card">
            <div class="py-1">
                <p-tabMenu [model]="tabMenuItems" styleClass="px-3"></p-tabMenu>
                <div *ngIf="showCommunications">
                    <app-comm-tab></app-comm-tab>
                </div>
                <div *ngIf="showDocuments">
                    <app-contract-documents></app-contract-documents>
                    <app-customer-attachments></app-customer-attachments>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="hasFeature('CSR_CUSTOMER_COMMUNICATIONS_NOTE_LIST', 'feature')" class="md:col-4 col-12">
        <app-customer-notes></app-customer-notes>
    </div>
</div>