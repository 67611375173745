<h1>Add Payment Method</h1>
<!-- <div mat-dialog-content> -->
<div mat-dialog-content>  
  <!-- <p class="mb-4">
      <mat-radio-group class="p-radio-group" [(ngModel)]="paymentType" color="primary">
          <mat-radio-button class="p-radio-button" [value]="p.id"  *ngFor="let p of paymentTypes">
              <strong>{{p.desc}}</strong>
          </mat-radio-button>
      </mat-radio-group>
  </p> -->
      <div *ngFor="let p of paymentTypes" class="p-field-checkbox p-2">
        <p-radioButton [inputId]="p.id" name="paymentType" [value]="p.id" [(ngModel)]="paymentType" class="pr-2"></p-radioButton>
        <label [for]="p.id">{{p.desc}}</label>
      </div>
    



    <form *ngIf="paymentType == 'debit' || paymentType == 'credit'" [formGroup]="cardForm">
        <div class="row">
            <div class="col-xs-12 col-sm-4">
                <app-cc-input controlName="number" maxLen='15' type="number" label="Card Number" [group]="cardForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-4">
              <app-cc-input controlName="exp" type="number" maxLen="4" label="Exp Date" [group]="cardForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-4">
              <app-cc-input controlName="cvv" type="number" maxLen='4'  label="CVV" [group]="cardForm"></app-cc-input>
            </div>
        </div>
      </form>
      <form *ngIf="paymentType == 'ach'" [formGroup]="achForm">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <app-cc-input controlName="account" type="number" label="Bank Account Number" [group]="achForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-6">
              <app-cc-input controlName="routing" type="number" label="Bank Routing Number" [group]="achForm"></app-cc-input>
            </div>
        </div>
      </form>
</div>
<div>
    <covered-button (click)="back()" class="secondary pr-3">Back</covered-button>
    <covered-button (click)="confirm()" class="primary">CONFIRM</covered-button>
</div>


<!-- <h1 mat-dialog-title>Add Payment Method</h1>
<div mat-dialog-content>
    <p class="mb-4">
        <mat-radio-group class="p-radio-group" [(ngModel)]="paymentType" color="primary">
            <mat-radio-button class="p-radio-button" [value]="p.id"  *ngFor="let p of paymentTypes">
                <strong>{{p.desc}}</strong>
            </mat-radio-button>
        </mat-radio-group>
    </p>

    <form *ngIf="paymentType == 'debit' || paymentType == 'credit'" [formGroup]="cardForm">
        <div class="row">
            <div class="col-xs-12 col-sm-4">
                <app-cc-input controlName="number" maxLen='15' type="number" label="Card Number" [group]="cardForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-4">
              <app-cc-input controlName="exp" type="number" maxLen='4' label="Exp Date" [group]="cardForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-4">
              <app-cc-input controlName="cvv" type="number" maxLen='4'  label="CVV" [group]="cardForm"></app-cc-input>
            </div>
        </div>
      </form>
      <form *ngIf="paymentType == 'ach'" [formGroup]="achForm">
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <app-cc-input controlName="account" type="number" label="Bank Account Number" [group]="achForm"></app-cc-input>
            </div>
            <div class="col-xs-12 col-sm-6">
              <app-cc-input controlName="routing" type="number" label="Bank Routing Number" [group]="achForm"></app-cc-input>
            </div>
        </div>
      </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="back()" color="primary">Back</button>
    <button mat-flat-button (click)="confirm()" color="primary">CONFIRM</button>
</div> -->