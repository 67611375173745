<app-dashboard-header></app-dashboard-header>
<div class="page-breadcrumb">
    <h4 class="page-title">Agent Tools</h4>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-8">
            <div class="row">
                <div class="col-4">
                    <div>
                        <covered-button label="Courtesy Adjustment" icon="pi pi-sort-alt text-4xl" class="p-button-lg w-12 py-4 text-2xl" (buttonClick)="courtesyAdjustmentLink();"></covered-button>
                    </div>                   
                </div>
                <div class="col-4">
                    <div>
                        <covered-button label="Manual Payments" icon="pi pi-book text-4xl" class="info text-white p-button-lg w-12 py-4 text-2xl" (buttonClick)="manualPaymentsLink();">
                        </covered-button>                        
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <covered-button label="Collections" icon="pi pi-chart-pie text-4xl" class="p-button-warning text-white p-button-lg w-12 py-4 text-2xl" (buttonClick)="collectionsLink();">                            
                        </covered-button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-lg-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>