<h1 mat-dialog-title>Customer Info Change</h1>
<div mat-dialog-content>
    <p class="mb-4">Confirm the following field(s) update(s):</p>
    <div class="table-responsive">
        <table class="table table-sm" aria-label="Customer Info Change">
            <thead>
              <tr>
                <th>Field</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of data">
                <td>{{c.field}}</td>
                <td>{{c.from}}</td>
                <td>{{c.to}}</td>
              </tr>
            </tbody>
          </table>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onClick('back')" color="primary">Back</button>
    <button mat-flat-button (click)="onClick('confirmed')" color="primary">CONFIRM</button>
</div>