import { Component, OnInit } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  ids: ActivatedIds = new ActivatedIds;
  customerInfo: CustomerInfoLoanModel = new CustomerInfoLoanModel;
  paymentMethods: PaymentMethodModel[] = [];
  loading = true;
  constructor(private main: MainService, 
    private dashboardService: DashboardService,
    private customerService: CustomerService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid){
        this.ids = id;
        this.main.showLoading();
        this.getLoanData();
      }
    });

    this.customerService.infoChanged$.subscribe(val => {
      if (val === 'changed'){
        this.getLoanData();
      }
    })
  }

  getLoanData(){
    this.loading = true;
    this.apiService.get(`csr/loan-data/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
    .subscribe((res: any) => {
      this.main.dismissLoading();
      this.loading = false;
      this.customerInfo = res.loanData;
      this.paymentMethods = res.paymentMethods || [];
      this.dashboardService.setLoanData(this.customerInfo);
      this.dashboardService.setPaymentMethods(this.paymentMethods);
    },
      (err: any) => {
        this.loading = false;
        this.main.dismissLoading();
        console.error(err);
      }
    );
  }

}
