<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="flex flex-column">        
        <div class="text-xl pl-3">Courtesy Adjustment</div>
        <p-breadcrumb [model]="items"></p-breadcrumb>        
    </div>
</div>

<div class="w-full px-1 mx-auto">
    <div class="flex">
        <div class="col-4">
            <div class="cds-card">
                <div class="flex-auto p-4">
                    <div class="text-xl font-bold mb-2">
                        Manual Payments
                    </div>
                    <div class="mb-3">
                        <form [formGroup]="manualPaymentForm">

                            <div class="pb-2">
                                <covered-input-number formControlName="paymentAmount" id="payAmt" placeholder="Enter Payment Amount" [required]="true">
                                <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!manualPaymentForm.controls['paymentAmount'].pristine && manualPaymentForm.controls['paymentAmount'].errors">
                                    <p *ngIf="manualPaymentForm.controls['paymentAmount'].errors?.['required']">
                                        Payment Amount is required</p>
                                </div>
                            </covered-input-number>
                            </div>
                            <div class="pb-2">
                                    <covered-dropdown id="payType" placeholder="Select Payment Type" formControlName="paymentType"
                                    [items]="paymentOptions" optionLabel="desc" [displayFirst]="false" [showClear]="true" [required]="true">
                                    <div class="w-12 my-1 p-error text-xs"
                                        *ngIf="manualPaymentForm.controls['paymentType'].touched && manualPaymentForm.controls['paymentType'].invalid">
                                        <p>Payment Type is required</p>
                                    </div>
                                </covered-dropdown>
                            </div>
                            <div class="pb-2">
                                    <covered-form-text id="refCode" placeholder="Payment Reference Code"
                                        formControlName="refCode" [required]="true">
                                        <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="!manualPaymentForm.controls['refCode'].pristine && manualPaymentForm.controls['refCode'].errors">
                                            <p *ngIf="manualPaymentForm.controls['refCode'].errors?.['required']">
                                                Reference Code is required</p>
                                        </div>
                                    </covered-form-text>
                            </div>
                            <div class="pb-4"> 
                                <covered-datepicker id="dtPayment" formControlName="paymentDate" placeholder="Payment Date" [required]="true"
                                    [minDate]="minDate" [maxDate]="maxDate">
                                    <div class="w-12 my-1 p-error text-xs"
                                    *ngIf="!manualPaymentForm.controls['paymentDate'].pristine && manualPaymentForm.controls['paymentDate'].errors">
                                    <p *ngIf="manualPaymentForm.controls['paymentDate'].errors?.['required']">
                                        Payment Date is required</p>
                                </div>
                                </covered-datepicker>
                            </div>
                            <covered-button buttonType="submit" (click)="confirmManualPayment()" [disabled]="manualPaymentForm.invalid">APPLY PAYMENT
                            </covered-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-offset-4 col-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>


<covered-toast [key]="toastKey" [position]="toastPos"></covered-toast>

<covered-dialog [isVisible]="showPaymentModal" header="Manual Payment Review" headerClass="text-2xl"
    [showHeader]="true" [isModal]="true" [isClosable]="true" (HideClick)="showPaymentModal=false;"
    [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="col-7 text-right">
                <span>Payment Amount: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['paymentAmount']}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-7 text-right">
                <span>Payment Method: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['paymentType'] ? manualPaymentForm.value.paymentType : 'Unknown'}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-7 text-right">
                <span>Reference Code: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['refCode']}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-7 text-right">
                <span>Payment Date: </span>
            </div>
            <div class="col-5 font-bold">
                <span>{{manualPaymentForm.value['paymentDate'] | date}}</span>
            </div>
        </div>
    </div>
    <div footer class="col-offset-5 col-7 justify-content-around">
        <covered-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3"
            (buttonClick)="showPaymentModal=false;"></covered-button>
        <covered-button id="btnDialog1Ok" label="Confirm" class="w-6" (buttonClick)="showPaymentModal=false;postManualPayment();"></covered-button>
    </div>
</covered-dialog>