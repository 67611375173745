import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manual-payments-page',
  templateUrl: './manual-payments-page.component.html',
  styleUrls: ['./manual-payments-page.component.scss']
})
export class ManualPaymentsPageComponent implements OnInit {
 
  items: MenuItem[] = [];
  showPaymentModal: boolean = false;
  toastKey: string = 'payToast';
  toastPos: string = 'bottom-center';

  minDate: Date = new Date();
  maxDate: Date = new Date();

  manualPaymentForm = this.fb.group({
    paymentAmount: [null, [Validators.required, Validators.pattern(/^\d*\.?\d*$/)]],
    paymentType: [null, [Validators.required]],
    refCode: [null, Validators.required],
    paymentDate: [this.maxDate, Validators.required]
  });

  paymentOptions: LookupModel[] = [
    {
      id: 'Check Payment',
      desc: 'Check Payment'
    },
    {
      id: 'Cash',
      desc: 'Cash'
    },
    {
      id: 'Cashiers Check',
      desc: 'Cashiers Check'
    },
    {
      id: 'Money Order',
      desc: 'Money Order'
    },
    {
      id: 'Western Union',
      desc: 'Western Union'
    },
    {
      id: 'Money Gram',
      desc: 'Money Gram'
    },
  ]

  customerGuid: any;
  targetGuid: any;
  campaignGuid: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private api: ApiService,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    let today = this.maxDate
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month <= 1) ? month + 10 : month - 2;
    let prevYear = (prevMonth >= 10) ? year - 1 : year;
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);

    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.customerGuid = paramMap.get('customerGuid')?.toString();
      this.campaignGuid = paramMap.get('campaignGuid')?.toString();
      this.targetGuid = paramMap.get('targetGuid')?.toString();

      this.items = [
        {
          label: "Agent Tools",
          url: `/agent-tools/${this.customerGuid}/${this.campaignGuid}/${this.targetGuid}`
        },
        {
          label: "Manual Payments"
        }
      ]
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  confirmManualPayment(): void {
    if (!this.manualPaymentForm.valid) {
      this.messageService.add({
        key: this.toastKey,
        severity: 'error',
        summary: 'Unable to process',
        detail: 'An error occurred. Please retry.'
      });
    } else {
      this.showPaymentModal = true;
    }
  }

  postManualPayment(): void {
    let body = {
      campaignGuid: this.campaignGuid,
      customerGuid: this.customerGuid,
      targetGuid: this.targetGuid,
      amount: this.manualPaymentForm.value.paymentAmount,
      paymentMethod: this.manualPaymentForm.value.paymentType,
      referenceCode: this.manualPaymentForm.value['refCode'],
      date: this.manualPaymentForm.value['paymentDate']
    }

    let postSub = this.api.postStringResponse('AgentTools/manual-payment', body)
      .subscribe({
        next: () => {
          this.messageService.add({
            key: this.toastKey,
            severity: 'success',
            summary: 'Success',
            detail: 'Manual payment created.'
          });
        },
        error: (err: any) => {
          this.messageService.add({
            key: this.toastKey,
            severity: 'error',
            summary: 'Unable to post payment',
            detail: 'An error occurred. Please retry.'
          });
        },
        complete: () => { postSub.unsubscribe(); }
      });
  }
}
