import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { ScheduledPaymentModel } from 'src/app/models/csr/scheduled-payment-model';
import { TargetTransactionHistory } from 'src/app/models/csr/target-transaction-history';
import { LookupModel } from 'src/app/models/lookup-model';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { TargetAccountSummary } from 'src/app/models/target-account-summary';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-servicing-page',
  templateUrl: './account-servicing-page.component.html',
  styleUrls: ['./account-servicing-page.component.scss'],
  providers: [NgbCarouselConfig]
})
export class AccountServicingPageComponent implements OnInit {
  accountSummary: TargetAccountSummary = new TargetAccountSummary();
  scheduledPayments: ScheduledPaymentModel[] = [];
  upcomingPayments: any[] = [];
  tenDaysPayoffs: any[] = [];
  transactionHistory: any[] = [];
  paymentAccounts: PaymentMethodModel[] = [];
  processors: LookupModel[] = [];
  ids: ActivatedIds = {};
  loading = false;
  lateFees = '';
  showNavigationArrows = false;
  showSchedule = true;
  showScheduleEdit = false;
  rows: number = 15;
  first: number = 0;
  showPayments: boolean = false;
  showPayoffs: boolean = false;
  @ViewChild(NgbCarousel)
  carousel: NgbCarousel | undefined;
  constructor(private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private guidService: GuidService,
    private userService: UserService,
    private main: MainService,
    private customerService: CustomerService, config: NgbCarouselConfig) {
    config.interval = 10000;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
    config.animation = true;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getAccountSummary();
          this.getTargetTransactionHistory();
        }
      }
    });
  }

  getAccountSummary() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.main.showLoading();
      this.loading = true;
      this.apiService.get(`csr/target-account-summary/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: any) => {
          this.loading = false;
          this.accountSummary = res.accountSummary;
          this.scheduledPayments = res.scheduledPayments || [];
          this.upcomingPayments = res.upcomingPayments || [];
          this.tenDaysPayoffs = res.tenDaysPayoffs || [];
          this.paymentAccounts = res.paymentAccounts || [];
          var arr = res.processors || [];
          arr.forEach((item: { processorID: any; processorDescription: any; }) => {
            this.processors.push({
              id: item.processorID,
              desc: item.processorDescription
            })
          });
          if (Number(this.accountSummary.lateFeesBalance) > 0) {
            this.lateFees = this.accountSummary.lateFeesBalance;
          }
          this.main.dismissLoading();
        },
          (err: any) => {
            console.error(err);
            this.loading = false;
            this.main.dismissLoading();
          }
        );
    }
  }

  getTargetTransactionHistory() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.apiService.get(`csr/target-transaction-history/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: TargetTransactionHistory[]) => {
          this.transactionHistory = res;
        },
          (err: any) => {
            console.error(err);
          }
        );
    }
  }

  sendSms() {
    this.main.showYesNoPopUp(null, 'Send "Make Payment" link (SMS)?', 'SEND').afterClosed().subscribe(result => {
      if (result && result == 'yes') {
        this.sendMakePayment('0');
      }
    });
  }

  sendEmail() {
    this.main.showYesNoPopUp(null, 'Send "Make Payment" link (Email)?', 'SEND').afterClosed().subscribe(result => {
      if (result && result == 'yes') {
        this.sendMakePayment('1');
      }
    });
  }

  copyLink() {
    this.main.showSnackBar("Link copied to clipboard");
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

showLoanAreementPdf(){
  window.open(this.accountSummary.loanAgreementPdfUrl, '_blank');
}
showWelcomePacket() {
  window.open(this.accountSummary.welcomePacketPDFUrl, '_blank'); 
}


  makePayment() {
    this.showScheduleEdit = true;
  }

  onBack(updated: boolean) {
    this.showScheduleEdit = false;
    if (updated){
      this.getAccountSummary();
    }
  }

  onSlid() {
    if (this.showScheduleEdit) {
      this.showSchedule = false;
    } else {
      this.showScheduleEdit = false;
    }
  }

  private sendMakePayment(sendEmail: string) {
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: sendEmail
    }
    var body = JSON.stringify(model);
    this.apiService.post(`csr/send-make-payment`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        var success = sendEmail === '1' ? "Sending Payment Link (email) message will be completed soon!" : "Sending Payment Link (SMS) message will be completed soon!";
        this.main.showSuccessModal("Success!", success);
      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Ops. Error happened while sending");
        }
      );
  }
}
