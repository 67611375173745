import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { TargetNoteModel } from 'src/app/models/target-note-model';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-notes',
  templateUrl: './cutomer-notes.component.html',
  styleUrls: ['./cutomer-notes.component.scss']
})
export class CutomerNotesComponent implements OnInit {

  ids: ActivatedIds = new ActivatedIds;
  loading = true;
  updating = false;
  notes: TargetNoteModel[] = [];
  noteListPageUrl = '';
  noteForm = this.fb.group({
    text: ['', Validators.required],
    categoryId: ['', Validators.required],
  })
  noteCategories: LookupModel[] = [];
  constructor(private main: MainService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private fb: FormBuilder,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid) {
        this.ids = id;
        this.noteListPageUrl = '/note-list/' + this.ids.customerGuid;
        this.getNotes();
      }
    });

    this.dashboardService.targetInfo$.subscribe(info => {
      // this.targetInfo = info;
      // this.initForm();
    });

    this.dashboardService.lookups$.subscribe(lookup => {
      this.noteCategories = lookup.noteCategories;
      var top = new LookupModel;
      top.desc = 'Disposition Code';
      top.id = '';
      this.noteCategories.unshift(top);
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  reload() {
    this.getNotes();
  }

  viewAll() {

  }

  getNotes(){
    this.loading = true;
    this.apiService.get(`csr/target-notes/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
    .subscribe((res: any) => {
      this.loading = false;
      this.notes = res || [];
    },
      (err: any) => {
        this.loading = false;
        console.error(err);
      }
    );
  }

 deleteNote(note: TargetNoteModel) {
    var body = JSON.stringify(note.noteGUID);
    this.updating = true;
    this.apiService.post(`csr/delete-target-notes/${this.ids.customerGuid}`, body)
      .subscribe((res: any) => {
        this.updating = false;
        this.getNotes();
      },
        (err: any) => {
          this.updating = false;
          this.getNotes();
          console.error(err);
        }
      );
  }

  addNote() {
    if (!this.noteForm.valid)
      return;

    var body = JSON.stringify(this.noteForm.value.text);
    this.updating = true;
    this.apiService.post(`csr/add-target-notes/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}/${this.noteForm.value.categoryId}`, body)
      .subscribe((res: any) => {
        this.updating = false;
        this.noteForm.setValue({
          text: '',
          categoryId: ""
        })
        this.noteForm.markAsUntouched();
        this.getNotes();
      },
        (err: any) => {
          this.updating = false;
          this.getNotes();
          console.error(err);
        }
      );
  }

  initForm() {
  }

  getBoolVal(val: string) {
    return val === 'True' ? true : false;
  }
}
