<div class="grid m-2">
    <div class="col-2">
        <button pButton class="p-button p-button-lg w-12 bg-orange-500 border-orange-500 text-center" label="Compose"
            (click)="opCompose.toggle($event)" icon="pi pi-angle-down" iconPos="right"></button>
        <p-overlayPanel #opCompose [showCloseIcon]="false" [showTransitionOptions]="'0.2s'" styleClass="px-4">
            <ng-template pTemplate>
                <div class="h-auto">
                    <div class="py-4 cursor-pointer border-bottom-1" (click)="opCompose.hide();clearSMS();loadSMS();">Free Form SMS</div>
                    <div class="py-4 cursor-pointer" (click)="opCompose.hide();loadEmail();">Free Form Email</div>
                </div>
            </ng-template>
        </p-overlayPanel>
        <p class="m-1"><small>Folders</small></p>
        <ul class="list-group folders">
            <li class="border-1 px-3 py-3 border-black-alpha-10 align-contents-center cursor-pointer" (click)="showMailbox('inbox')">
                <i class="pi pi-envelope text-xl" aria-hidden="true"></i>
                <span class="mx-3">Inbox</span>
                <p-badge [value]="inboxUnread.toString()"></p-badge>
            </li>
            <li class="border-1 px-3 py-3 border-black-alpha-10 align-contents-center cursor-pointer" (click)="showMailbox('draft')">
                <i class="pi pi-file text-xl" aria-hidden="true"></i>
                <span class="mx-3">Draft</span>
                <p-badge *ngIf="draftsCount > 0" [value]="draftsCount.toString()"></p-badge>
            </li>
            <li class="border-1 px-3 py-3 border-black-alpha-10 align-contents-center cursor-pointer h-max-content"
                (click)="showTemplateChoices = !showTemplateChoices">
                <i class="pi pi-folder text-xl" aria-hidden="true"></i>
                <span class="mx-3">Templates</span>
                <i class="pi pi-angle-down" aria-hidden="true"></i>
                <div *ngIf="showTemplateChoices" class="w-12 py-5 animate__animated animate__fadeInDown" (click)="loadTemplates();">
                    <span class="py-4 px-3 text-center text-sm">Customer Support</span>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-10">
        <div *ngIf="showMailboxes">
            <app-comm-mailbox (setCount)="setCount($event)" [mailbox]="mailbox" [mailboxLabel]="mailboxLabel"></app-comm-mailbox>
        </div>
        <div *ngIf="showTemplates">
            <div class="text-2xl mb-4">Customer Support Templates</div>
            <div *ngFor="let t of templates">
                <div class="comm-template-item" (click)="loadTemplate(t)">
                    <p-badge [styleClass]="getBadgeStyle(t)"></p-badge>
                    <span>{{ t.subject }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="showSMS">
            <app-comm-sms [customerInfo]="customerInfo" [messageTemplateGuid]="smsMsgTempGuid" [ids]="ids"
                (onBack)="showMailbox('inbox')" (loadMsg)="addMessage($event)">
            </app-comm-sms>
        </div>
        <div *ngIf="showEmail">
            <app-comm-email [customerInfo]="customerInfo" [messageTemplateGuid]="smsMsgTempGuid" [ids]="ids"
                (onBack)="showMailbox('inbox')" (loadMsg)="addMessage($event)">
            </app-comm-email>
        </div>
    </div>
</div>


<covered-toast [key]="toastKey" [position]="toastPos"></covered-toast>