import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { TargetMessageModel } from 'src/app/models/communications/message-model';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-comm-mailbox-item',
  templateUrl: './comm-mailbox-item.component.html',
  styleUrls: ['./comm-mailbox-item.component.scss']
})
export class CommMailboxItemComponent implements OnInit {

  @Input() message:TargetMessageModel = new TargetMessageModel();
  @Output() onBack = new EventEmitter<boolean>();
  loading = false;
  ids: ActivatedIds = {};
  emailBody: any;
  messageSubject: string = '';

  constructor(
    private main: MainService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
          this.getDetails();
        }
      }
    });
  }

  getDetails(){
    this.loading = true;
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: this.message.messageGUID
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/target-message-details`, body)
      .subscribe((res: any) => {
        if (res.messageTypeID === '4') {
          this.messageSubject = "SMS";          
        }
        else {
          this.messageSubject = res.emailSubject;
        }
        this.emailBody = res.emailBody || this.message.objectBody;
        this.main.dismissLoading();
        this.loading = false;

      },
        (err: any) => {
          this.main.dismissLoading();
          this.main.showSnackBar("Ops. Error happened");
          this.loading = false;
        }
      );
  }

  back(){
    this.onBack.emit(true);
  }

}
