import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmChangeData } from 'src/app/components/confirm-change-modal/confirm-change-modal.component';
import { AccountSummary } from 'src/app/models/account-summary';
import { CardPayment } from 'src/app/models/card-payment';
import { CourtesyAdjustmentInfo } from 'src/app/models/courtesy-adjustment-info';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-courtesy-adjustment-page',
  templateUrl: './courtesy-adjustment-page.component.html',
  styleUrls: ['./courtesy-adjustment-page.component.scss']
})
export class CourtesyAdjustmentPageComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private api: ApiService,
    private builder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService) { }

  customerGuid: any;
  targetGuid: any;
  campaignGuid: any;

  validateAccountAdjustment: boolean = false;

  accountSummary: AccountSummary = new AccountSummary();

  cardPayments: CardPayment[] = [];
  selectedPayment: CardPayment | null = null;

  info: CourtesyAdjustmentInfo = new CourtesyAdjustmentInfo();
  selectedMethod: PaymentMethodModel | null = null;
  bankAmount: number = 0;

  totalAdjustment: number = 0.00;

  accountAdjustments: FormGroup = this.builder.group({
    principal: [0.00, Validators.required],
    interest: [0.00, Validators.required],
    lateFee: [0.00, Validators.required]
  });

  manualRefundAmount: number = 0.00;
  disableCreditCardPayment: boolean = true;

  manualBankAdjustment: number = 0.00;
  disableBankPayment: boolean = true;
  manualBankAdjBlur: boolean = false;

  showAcctAdjModal: boolean = false;
  showCreditCardModal: boolean = false;
  showBankAcctModal: boolean = false;
  toastKeyAcct: string = 'acctToast';
  toastKeyCredit: string = 'creditToast';
  toastKeyBank: string = 'bankToast';
  toastPos: string = 'bottom-center';


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.customerGuid = paramMap.get('customerGuid')?.toString();
      this.campaignGuid = paramMap.get('campaignGuid')?.toString();
      this.targetGuid = paramMap.get('targetGuid')?.toString();
      this.customerService.initCustomer(this.customerGuid);
    });

    this.api.get(`AgentTools/account-summary?targetGuid=${this.targetGuid}&campaignGuid=${this.campaignGuid}&customerGuid=${this.customerGuid}`)
      .subscribe((r) => {
        this.accountSummary = r;
        this.accountAdjustments.addValidators([AdjustmentValidator(this.accountSummary)]);
      })

    this.api.get(`AgentTools/card-payments?targetGuid=${this.targetGuid}&campaignGuid=${this.campaignGuid}&customerGuid=${this.customerGuid}`)
      .subscribe((r) => {
        this.cardPayments = r;
      })

    this.api.get(`AgentTools/courtesy-adjustment-info?targetGuid=${this.targetGuid}&campaignGuid=${this.campaignGuid}&customerGuid=${this.customerGuid}`)
      .subscribe((r) => {
        this.info = r;
      });

  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  confirmAccountAdjustment() {
    if (!this.accountAdjustments.valid) {
      this.messageService.add({
        key: this.toastKeyAcct,
        severity: 'error',
        summary: 'Unable to process',
        detail: 'An error occurred. Please retry.'
      });
    } else {
      this.showAcctAdjModal = true;
    }
  }

  postAccountAdjustment() {
    let cashAdvanceFeeAmount = +this.accountAdjustments?.value['principal'] ?? 0;
    let fixedFinanceFeeAmount = +this.accountAdjustments?.value['interest'] ?? 0;
    let lateFeeAmount = +this.accountAdjustments?.value['lateFee'] ?? 0;
    if (cashAdvanceFeeAmount > 0 || fixedFinanceFeeAmount > 0 || lateFeeAmount > 0) {
      if ((cashAdvanceFeeAmount == 0 || cashAdvanceFeeAmount <= this.accountSummary.principalBalance) && (fixedFinanceFeeAmount == 0 || fixedFinanceFeeAmount <= this.accountSummary.interestBalance) && (lateFeeAmount == 0 || lateFeeAmount <= this.accountSummary.feesBalance)) {

        let body = {
          TargetGuid: this.targetGuid,
          CampaignGuid: this.campaignGuid,
          CustomerGuid: this.customerGuid,
          Type: 'account',
          CashAdvanceFeeAmount: cashAdvanceFeeAmount,
          FixedFinanceFeeAmount: fixedFinanceFeeAmount,
          LateFeeAmount: lateFeeAmount,
          PaylianceTransactionQueueId: -1
        };

        let postSub = this.api.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
          .subscribe({
            next: () => {
              this.messageService.add({
                key: this.toastKeyAcct,
                severity: 'success',
                summary: 'Success',
                detail: 'Account Adjustment added.'
              });
            },
            error: (err: any) => {
              this.messageService.add({
                key: this.toastKeyAcct,
                severity: 'error',
                summary: 'Unable to make account adjustment',
                detail: 'An error occurred. Please retry.'
              });
            },
            complete: () => { postSub.unsubscribe(); }
          })
      }
      else {
        let msg = '';
        if (cashAdvanceFeeAmount > this.accountSummary.principalBalance) {
          msg = `Principal Adjustment must be less than or equal to $${this.accountSummary.principalBalance}.`
        } else if (fixedFinanceFeeAmount > this.accountSummary.interestBalance) {
          msg = `Interest Adjustment must be less than or equal to $${this.accountSummary.interestBalance}.`
        } else if (lateFeeAmount > this.accountSummary.feesBalance) {
          msg = `Late Fee Adjustment must be less than or equal to $${this.accountSummary.feesBalance}.`
        }
        if (msg) {
          this.messageService.add({
            key: this.toastKeyCredit,
            severity: 'error',
            summary: 'Unable to make account adjustment',
            detail: msg
          });
        }
      }
    }
  }

  updateTotalAdjustment($event: any) {
    setTimeout(() => {
      this.totalAdjustment = Number(this.accountAdjustments?.value['principal'] ?? 0)
        + Number(this.accountAdjustments?.value['interest'] ?? 0)
        + Number(this.accountAdjustments?.value['lateFee'] ?? 0);
    }, 200);
  }

  confirmCardAdjustment() {
    if (this.disableCreditCardPayment) {
      this.messageService.add({
        key: this.toastKeyCredit,
        severity: 'error',
        summary: 'Unable to process',
        detail: 'An error occurred. Please retry.'
      });
    } else {
      this.showCreditCardModal = true;
    }
  }

  postCardAdjustment() {
    let body = {
      TargetGuid: this.targetGuid,
      CampaignGuid: this.campaignGuid,
      CustomerGuid: this.customerGuid,
      Type: 'card',
      CashAdvanceFeeAmount: 0,
      FixedFinanceFeeAmount: 0,
      LateFeeAmount: 0,
      cardAmount: this.manualRefundAmount,
      PaylianceTransactionQueueId: this.selectedPayment?.paylianceTransactionQueueID
    };

    let postSub = this.api.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
      .subscribe({
        next: () => {
          this.messageService.add({
            key: this.toastKeyCredit,
            severity: 'success',
            summary: 'Success',
            detail: 'Credit Card Adjustment added.'
          });
        },
        error: (err: any) => {
          this.messageService.add({
            key: this.toastKeyCredit,
            severity: 'error',
            summary: 'Unable to make credit card adjustment',
            detail: 'An error occurred. Please retry.'
          });
        },
        complete: () => { postSub.unsubscribe(); }
      })
  }

  confirmBankAdjustment() {
    if (this.disableBankPayment) {
      this.messageService.add({
        key: this.toastKeyCredit,
        severity: 'error',
        summary: 'Unable to process',
        detail: 'An error occurred. Please retry.'
      });
    } else {
      this.showBankAcctModal = true;
    }
  }

  postBankAdjustment() {
    if (this.manualBankAdjustment && this.manualBankAdjustment <= this.accountSummary.principalBalance) {
      let body = {
        TargetGuid: this.targetGuid,
        CampaignGuid: this.campaignGuid,
        CustomerGuid: this.customerGuid,
        Type: 'bank',
        CashAdvanceFeeAmount: 0,
        FixedFinanceFeeAmount: 0,
        LateFeeAmount: 0,
        cardAmount: 0,
        PaylianceTransactionQueueId: -1,
        bankAccountAmount: this.manualBankAdjustment,
        loanPaymentMethodGuid: this.selectedMethod?.loanPaymentMethodGUID
      };

      let postSub = this.api.postStringResponse(`AgentTools/courtesy-adjustment/`, body)
        .subscribe({
          next: () => {
            this.messageService.add({
              key: this.toastKeyBank,
              severity: 'success',
              summary: 'Success',
              detail: 'Bank Adjustment made.'
            });
          },
          error: (err: any) => {
            this.messageService.add({
              key: this.toastKeyBank,
              severity: 'error',
              summary: 'Unable to make bank account adjustment',
              detail: 'An error occurred. Please retry.'
            });
          },
          complete: () => { postSub.unsubscribe(); }
        });
    } else {
      this.messageService.add({
        key: this.toastKeyBank,
        severity: 'error',
        summary: 'Unable to make bank account adjustment',
        detail: `Refund amount must be less than or equal to $${this.accountSummary.principalBalance}.`
      });
    }
  }
  
  setPaymentRefund(payment: CardPayment) {
    setTimeout(() => {
      this.manualRefundAmount = payment.amount;
      if (this.selectedPayment && (this.manualRefundAmount <= this.selectedPayment?.amount)) {
        this.disableCreditCardPayment = false;
      } else {
        this.disableCreditCardPayment = true;
      }
    }, 100);
  }

  validateCreditCard() {
    setTimeout(() => {
      if (this.selectedPayment && (this.manualRefundAmount <= this.selectedPayment?.amount)) {
        this.disableCreditCardPayment = false;
      } else {
        this.disableCreditCardPayment = true;
      }
    }, 100);

    return true;
  }

  validateBankAmount() {
    if (!this.manualBankAdjBlur) {
      return;
    }
    setTimeout(() => {
      if (!this.manualBankAdjustment || this.manualBankAdjustment == 0.00 || !this.selectedMethod) {
        this.disableBankPayment = true;
      } else {
        this.disableBankPayment = false;
      }
    }, 100);

    return true;
  }
}

export function AdjustmentValidator(accountSummary: AccountSummary): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let form = control as FormGroup;
    let hasError: boolean = false;
    let errors: any = {};
    
    let principal = Number(form.get('principal')?.value ?? 0.00);
    let interest = Number(form.get('interest')?.value ?? 0.00);
    let lateFee = Number(form.get('lateFee')?.value ?? 0.00);

    let princAS = accountSummary.principalBalance;
    let intAS = accountSummary.interestBalance;
    let feeAS = accountSummary.feesBalance;

    if (principal == 0 && interest == 0 && lateFee == 0) {
      hasError = true;
      form.controls['principal'].setErrors({min:true});
      form.controls['interest'].setErrors({min:true});
      form.controls['lateFee'].setErrors({min:true});
      return null;
    }

    if (principal > princAS) {
      hasError = true;
      form.controls['principal'].setErrors({max:true});
    }
    else {
      form.controls['principal'].setErrors(null);
    }
    
    if (interest > intAS) {
      hasError = true;
      form.controls['interest'].setErrors({max:true});
    }
    else {
      form.controls['interest'].setErrors(null);
    }
    
    if (lateFee > feeAS) {
      hasError = true;
      form.controls['lateFee'].setErrors({max:true});
    }
    else {
      form.controls['lateFee'].setErrors(null);
    }
    
    return hasError ? errors : null;
  }
}
