<div class="cds-card p-3">
    <div>
        <p class="h4 ms-2 my-2">Notifications</p>
        <div *ngIf="!loading" class="mx-1 pt-2">
            <div *ngFor="let n of notifications" 
                class="p-1 mb-1 notification-item" 
                [ngClass]="getNotificationClass(n.notificationCardPriorityID)">
                    <p class="mb-1 text-muted"><small>{{n.createDate}}</small><small class="ms-1">-</small><small class="ms-1">{{n.fullName}}</small></p>
                    <p class="mb-1">{{n.note}}</p>
            </div>
        </div>
        <div class="my-2 mx-1">
            <textarea class="form-control border-round p-3"
             id="notificationTextarea" 
             [formControl]="notificationText"
             rows="2" 
             [ngClass]="notificationText.touched && notificationText.invalid ? 'is-invalid' : ''"
             placeholder="Add a notification here and select type of alert"></textarea>
             <div *ngIf="notificationText && notificationText.touched && notificationText.invalid" class="invalid-feedback">
                This field is invalid
              </div>
          </div>
          <div class="p-1 mx-1" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_NOTIFICATION')" >
            <covered-button [matMenuTriggerFor]="notificationMenu" [disabled]="loading || updating">
                Priority
                <mat-icon>arrow_drop_down</mat-icon>
              </covered-button>
              <mat-menu #notificationMenu="matMenu">
                <button mat-menu-item (click)="add('3')">
                  <span>None</span>
                </button>
                <button mat-menu-item (click)="add('2')">
                  <span>Medium</span>
                </button>
                <button mat-menu-item (click)="add('1')">
                  <span>High</span>
                </button>
              </mat-menu>
        </div>
      </div>
  </div>

  <!-- <mat-card class="mat-card-sm my-2">
    <mat-card-content>
        <p class="h4 ms-2 my-2">Notifications</p>
        <div *ngIf="!loading" class="mx-1">
            <div *ngFor="let n of notifications" 
                class="p-1 mb-1 notification-item" 
                [ngClass]="getNotificationClass(n.notificationCardPriorityID)">
                    <p class="mb-1 text-muted"><small>{{n.createDate}}</small><small class="ms-1">-</small><small class="ms-1">{{n.fullName}}</small></p>
                    <p class="mb-1">{{n.note}}</p>
            </div>
        </div>
        <div class="my-2 mx-1">
            <textarea class="form-control"
             id="notificationTextarea" 
             [formControl]="notificationText"
             rows="2" 
             [ngClass]="notificationText.touched && notificationText.invalid ? 'is-invalid' : ''"
             placeholder="Add a notification here and select type of alert"></textarea>
             <div *ngIf="notificationText && notificationText.touched && notificationText.invalid" class="invalid-feedback">
                This field is invalid
              </div>
          </div>
          <div class="p-1 mx-1" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_NOTIFICATION')" >
            <button mat-button [matMenuTriggerFor]="notificationMenu"  class="btn-main" [disabled]="loading || updating">
                Priority
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #notificationMenu="matMenu">
                <button mat-menu-item (click)="add('3')">
                  <span>None</span>
                </button>
                <button mat-menu-item (click)="add('2')">
                  <span>Medium</span>
                </button>
                <button mat-menu-item (click)="add('1')">
                  <span>High</span>
                </button>
              </mat-menu>
        </div>
    </mat-card-content>
  </mat-card>
   -->