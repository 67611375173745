<div class="cds-card p-3">
    <mat-card-content>
        <div class="d-flex">
            <div class="p-1"><p class="h4 ms-2">Notes</p></div>
            <div class="p-1">
                <a class="pl-2 text-black hover:text-blue-300" [routerLink]="noteListPageUrl">(View All)</a>
            </div>
            <div class="ms-auto p-1">
                <button mat-button (click)="reload()">reload</button>
            </div>
          </div>

        <form [formGroup]="noteForm">
            <div class="m-1">
                <app-cc-textarea [group]="noteForm" controlName="text"></app-cc-textarea>
            </div>
            <div class="m-1">
                <app-cc-select controlName="categoryId" label="" [group]="noteForm" [options]="noteCategories">
                </app-cc-select>
            </div>
            <div *ngIf="noteForm.valid" class="d-flex justify-content-end">
                <div class="p-1">
                    <button mat-flat-button color="primary" class="btn-brand" (click)="addNote()">Save</button>
                </div>
            </div>
        </form>
        <div *ngIf="!loading" class="m-1">
            <div *ngFor="let n of notes">
                <mat-divider></mat-divider>
                <p class="text-muted p-2 m-0">
                    <small class="me-1">{{n.firstName}}</small>
                    <small class="me-1">{{n.lastName}}</small>
                    <small class="me-1">{{n.createDate}}</small>
                </p>
                <p class="ms-2 mb-1 me-2">
                    <span class="me-1">{{n.note}}</span>
                    <button mat-button color="warn" 
                    (click)="deleteNote(n)"
                    *ngIf="hasFeature('CSR_CUSTOMER_COMMUNICATIONS_NOTE_DELETE')"
                    class="mat-btn-sm">Delete</button>
                </p>
            </div>
        </div>
    </mat-card-content>
</div>


<!-- <mat-card class="mat-card-sm my-2">
    <mat-card-content>
        <div class="d-flex">
            <div class="p-1"><p class="h4 ms-2">Notes</p></div>
            <div class="p-1">
                <a mat-button [routerLink]="noteListPageUrl">(View All)</a>
            </div>
            <div class="ms-auto p-1">
                <button mat-button (click)="reload()">reload</button>
            </div>
          </div>

        </div>
        <form [formGroup]="noteForm">
            <div class="m-1">
                <app-cc-textarea [group]="noteForm" controlName="text"></app-cc-textarea>
            </div>
            <div class="m-1">
                <app-cc-select controlName="categoryId" label="" [group]="noteForm" [options]="noteCategories">
                </app-cc-select>
            </div>
            <div *ngIf="noteForm.valid" class="d-flex justify-content-end">
                <div class="p-1">
                    <button mat-flat-button color="primary" class="btn-brand" (click)="addNote()">Save</button>
                </div>
            </div>
        </form>
        <div *ngIf="!loading" class="m-1">
            <div *ngFor="let n of notes">
                <mat-divider></mat-divider>
                <p class="text-muted p-2 m-0">
                    <small class="me-1">{{n.firstName}}</small>
                    <small class="me-1">{{n.lastName}}</small>
                    <small class="me-1">{{n.createDate}}</small>
                </p>
                <p class="ms-2 mb-1 me-2">
                    <span class="me-1">{{n.note}}</span>
                    <button mat-button color="warn" 
                    (click)="deleteNote(n)"
                    *ngIf="hasFeature('CSR_CUSTOMER_DELETE_NOTES')"
                    class="mat-btn-sm">Delete</button>
                </p>
            </div>
        </div>
    </mat-card-content>
  </mat-card> -->