<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <div class="p-4">
    <p *ngIf="data.subTitle" class="mb-2">{{data.subTitle}}</p>
    <p><strong>{{data.msg}}</strong></p>
  </div>
</div>
<div class="btn-toolbar">
  <covered-button (click)="onClick('no')" class="outline p-button-lg px-4">{{data.noText}}</covered-button>
  <covered-button (click)="onClick('yes')" class="p-button-lg px-4">{{data.yesText}}</covered-button>
</div>
