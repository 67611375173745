<form [formGroup]="search">
    <div class="cds-card p-6 col-8">        
        <div class="row">
            <div class="col-6">
                <app-cc-input controlName="account" label="Account Number" placeholder="Exact Match Only" [group]="search"></app-cc-input>
            </div>
            <div class="col-6">
                <app-cc-input controlName="email" label="Email Address" [group]="search"></app-cc-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-cc-input controlName="firstName" label="First Name" [group]="search"></app-cc-input>
            </div>        
            <div class="col-6">
                <app-cc-select controlName="state" label="State" [group]="search" type="states"></app-cc-select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-cc-input controlName="lastName" label="Last Name" [group]="search"></app-cc-input>
            </div>        
            <div class="col-6">
                <app-cc-input controlName="socialSecurityNumber" label="Social Security Number" placeholder="Exact Match Only" [group]="search"></app-cc-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-cc-input controlName="phone" label="Phone Number" [group]="search"></app-cc-input>
            </div>
            <div class="col-6">
                <app-cc-select controlName="appStatusID" label="Application Status" [group]="search" [options]="appStatuses"></app-cc-select>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <app-cc-select controlName="legalStatusID" label="Legal Status" [group]="search" [options]="legalStatuses"></app-cc-select>
            </div>
            <div class="col-6">
                <app-cc-input controlName="patientID" label="Patient ID" [group]="search" placeholder="Exact Match Only"></app-cc-input>
            </div>
        </div>
        <div class="col-offset-10 pb-5">
            <covered-button class="p-button-lg w-12" (click)="submitSearch()" label="Search" icon="pi pi-search">
                <!-- <i class="pi pi-search"></i> -->
            </covered-button>
        </div>        
    </div>
</form>


<!-- <form [formGroup]="search">
    <div class="card col-8">
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <app-cc-input controlName="account" label="Account Number" placeholder="Exact Match Only" [group]="search"></app-cc-input>
                </div>
                <div class="col-6">
                    <app-cc-input controlName="email" label="Email Address" [group]="search"></app-cc-input>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-cc-input controlName="firstName" label="First Name" [group]="search"></app-cc-input>
                </div>        
                <div class="col-6">
                    <app-cc-select controlName="state" label="State" [group]="search" type="states"></app-cc-select>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-cc-input controlName="lastName" label="Last Name" [group]="search"></app-cc-input>
                </div>        
                <div class="col-6">
                    <app-cc-input controlName="socialSecurityNumber" label="Social Security Number" placeholder="Exact Match Only" [group]="search"></app-cc-input>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-cc-input controlName="phone" label="Phone Number" [group]="search"></app-cc-input>
                </div>
                <div class="col-6">
                    <app-cc-select controlName="appStatusID" label="Application Status" [group]="search" [options]="appStatuses"></app-cc-select>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-cc-select controlName="legalStatusID" label="Legal Status" [group]="search" [options]="legalStatuses"></app-cc-select>
                </div>
                <div class="col-6">
                    <app-cc-input controlName="patientID" label="Patient ID" [group]="search" placeholder="Exact Match Only"></app-cc-input>
                </div>
            </div>
            <div class="col-12">
                <button class="btn btn-success float-end" (click)="submitSearch()">
                    <mat-icon>search</mat-icon>Search
                </button>
            </div>
        </div>    
    </div>
</form> -->
