import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { SendCommMessageModel } from 'src/app/models/communications/send-message-model';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-comm-sms',
  templateUrl: './comm-sms.component.html',
  styleUrls: ['./comm-sms.component.scss']
})
export class CommSmsComponent implements OnInit, OnChanges {

  @Output() onBack = new EventEmitter();
  @Output() loadMsg = new EventEmitter<Message>();
  @Input() ids: ActivatedIds = new ActivatedIds;
  @Input() customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
  @Input() messageTemplateGuid: any = null;

  smsTo: any;
  smsMsg: string | null = null;
  customerName: string = '';
  disableTo: boolean = false;

  defaultMessageTypeId: number = 4;
  isTemplate:boolean = false;
  templateName: string = '';

  constructor(
    private customerService: CustomerService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    if (this.customerInfo && this.customerInfo.phone) {
      this.smsTo = `${this.customerInfo.phone} (${this.customerInfo.firstName} ${this.customerInfo.lastName})`;
      this.disableTo = true;
    }

    this.checkGetMessageTemplate();
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageTemplateGuid']) {
      this.smsMsg = null;
      this.checkGetMessageTemplate();
    }
  }

  checkGetMessageTemplate() { 

    if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
      .subscribe({
        next: (res: any) => {
          if (res && res.body && res.body.length) {
            this.smsMsg = res.body;
            this.isTemplate = true;
            this.templateName = res.subject;
          }
        },
        error: (err: any) => { console.error(err); },
        complete: () => { custSub.unsubscribe(); }
      })
    }
    else {
      this.smsMsg = null;
      this.isTemplate = false;
    }
  }

  sendSMS() {
    let commMsg = new SendCommMessageModel();
    commMsg.TargetGuid = this.ids.targetGuid;
    commMsg.CampaignGuid = this.ids.campaignGuid;
    commMsg.CustomerGuid = this.ids.customerGuid || undefined;
    commMsg.MessageTypeId = this.defaultMessageTypeId;
    commMsg.MessageCategoryId = this.getMessageCategory();
    commMsg.IsDraft = false;
    commMsg.SmsMessage = this.smsMsg || '';
    commMsg.SmsPhoneNumber = this.customerInfo.phone;
  
    let msg: Message = {} as Message;

    this.loadMsg.emit(msg);
    this.back();
    let apiPost = this.apiService.post(`csr/communication-send`, commMsg)
    .subscribe({
      next: (res: any) => {             
        msg.severity = 'success';
        msg.summary = 'Success';
        msg.detail = "Message will be sent shortly";
        this.loadMsg.emit(msg);
        this.back();
      },
      error: (err: any) => {
        console.error(err);
        msg.severity = 'error',
        msg.summary = 'Error',
        msg.detail = 'Error sending message!'
        this.loadMsg.emit(msg);
      },
      complete: () => { apiPost.unsubscribe(); }
    });

  }

  getMessageCategory(): number {
    return 1;
  }

  back() {
    this.onBack.emit();
  }

  discard() {
    this.customerInfo = {} as CustomerInfoLoanModel;
    this.smsMsg = null;
    this.back();
  }
}
