<p class="h6 mb-1 pl-3">Personal Info</p>

  <form [formGroup]="loanForm">
    <div class="row p-3">
        <div class="col-sm-6 col-md-4">
            <app-cc-input controlName="firstName" label="First Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-6 col-md-4">
          <app-cc-input controlName="middleName" label="Middle Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-6 col-md-4">
          <app-cc-input controlName="lastName" label="Last Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel">Date of Birth</p>
            <p class="cvalue">{{customerInfo.dOB}}</p>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel"><span class="me-2">Email Address</span>
            <span><button mat-button color="primary" (click)="editEmail()">Edit</button></span></p>
            <p class="cvalue">{{customerInfo.emailAddress}}</p>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="address1" label="Address1" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="address2" label="Address2" [group]="loanForm"></app-cc-input>
      </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="city" label="City" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <app-cc-select controlName="state" label="State" [group]="loanForm" type="states"></app-cc-select>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="zipCode" label="Postal Code" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel">Country</p>
            <p class="cvalue">{{customerInfo.country}}</p>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="phone" label="Home Phone" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="cellPhone" label="Cell Phone" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="sSN" label="Social Security Number" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference1FirstName" label="Reference 1 First Name" [group]="loanForm" placeholder="First Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference1LastName" label="Reference 1 Last Name" [group]="loanForm" placeholder="Last Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference2FirstName" label="Reference 2 First Name" [group]="loanForm" placeholder="First Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference2LastName" label="Reference 2 Last Name" [group]="loanForm" placeholder="Last Name"></app-cc-input>
        </div>
      </div>
      <mat-divider></mat-divider>
      <p class="h6 mb-1 mt-2 pl-3">Other</p>
      <div class="row p-3">
          <div class="col-sm-6">
              <p class="clabel">Location Id</p>
              <p class="cvalue">{{customerInfo.branchLocationID}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Location Name</p>
              <p class="cvalue">{{customerInfo.branchLocationName}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Patient Id</p>
              <p class="cvalue">{{customerInfo.leadPatientID}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Service Date</p>
              <p class="cvalue">{{customerInfo.serviceDate}}</p>
          </div>
        </div>
        <div class="my-2 p-3">
          <p class="clabel">Payment Methods</p>
          <div *ngIf="paymentErrors.length" class="mb-2">
              <div *ngFor="let e of paymentErrors" class="alert alert-danger d-flex align-items-center" role="alert">
                  <mat-icon class="bi flex-shrink-0 me-2">error_outline</mat-icon>
                  <div>
                      {{e}}
                  </div>
                </div>
          </div>
          <div *ngFor="let p of paymentMethods">
              <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                      <app-payment-method-info [p]="p"></app-payment-method-info>
                  </div>
                  <div class="flex-shrink-0 ms-1">
                      <button mat-icon-button class="menu-icon" (click)="deletePaymentMethod(p)">
                          <!-- <mat-icon>delete</mat-icon> -->
                          <i class="pi pi-trash" aria-hidden="true"></i>
                        </button>
                  </div>
                </div>
          </div>
          <div class="mb-1">
              <button mat-button (click)="addPaymentMethod()">Add Payment Method</button>
          </div>
        </div>
  </form>
  <div class="mb-3 p-3">
    <label for="primaryPaymentSelect" class="form-label clabel">Primary Payment Method</label>
     <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'primary')"
      id="primaryPaymentSelect">
        <option value="">Choose...</option>
        <option *ngFor="let item of paymentMethods" [selected]="item.isPrimary === 'True'">{{item.loanPaymentMethodDescription}}</option>
      </select>
  </div>

  <div class="mb-3 p-3">
    <label for="primaryPaymentSelect" class="form-label clabel">Secondary Payment Method</label>
     <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'secondary')"
      id="primaryPaymentSelect">
      <option  value="">Choose...</option>
        <option *ngFor="let item of paymentMethods" [selected]="item.isSecondary === 'True'">{{item.loanPaymentMethodDescription}}</option>
      </select>
  </div>

<!-- <p class="h6 mb-1">Personal Info</p>

  <form [formGroup]="loanForm">
    <div class="row">
        <div class="col-sm-6 col-md-4">
            <app-cc-input controlName="firstName" label="First Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-6 col-md-4">
          <app-cc-input controlName="middleName" label="Middle Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-6 col-md-4">
          <app-cc-input controlName="lastName" label="Last Name" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel">Date of Birth</p>
            <p class="cvalue">{{customerInfo.dOB}}</p>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel"><span class="me-2">Email Address</span>
            <span><button mat-button color="primary" (click)="editEmail()">Edit</button></span></p>
            <p class="cvalue">{{customerInfo.emailAddress}}</p>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="address1" label="Address1" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="address2" label="Address2" [group]="loanForm"></app-cc-input>
      </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="city" label="City" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <app-cc-select controlName="state" label="State" [group]="loanForm" type="states"></app-cc-select>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="zipCode" label="Postal Code" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
            <p class="clabel">Country</p>
            <p class="cvalue">{{customerInfo.country}}</p>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="phone" label="Home Phone" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="cellPhone" label="Cell Phone" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12">
          <app-cc-input controlName="sSN" label="Social Security Number" [group]="loanForm"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference1FirstName" label="Reference 1 First Name" [group]="loanForm" placeholder="First Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference1LastName" label="Reference 1 Last Name" [group]="loanForm" placeholder="Last Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference2FirstName" label="Reference 2 First Name" [group]="loanForm" placeholder="First Name"></app-cc-input>
        </div>
        <div class="col-sm-12 col-md-6">
          <app-cc-input controlName="reference2LastName" label="Reference 2 Last Name" [group]="loanForm" placeholder="Last Name"></app-cc-input>
        </div>
      </div>
      <mat-divider></mat-divider>
      <p class="h6 mb-1 mt-2">Other</p>
      <div class="row">
          <div class="col-sm-6">
              <p class="clabel">Location Id</p>
              <p class="cvalue">{{customerInfo.branchLocationID}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Location Name</p>
              <p class="cvalue">{{customerInfo.branchLocationName}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Patient Id</p>
              <p class="cvalue">{{customerInfo.leadPatientID}}</p>
          </div>
          <div class="col-sm-6">
              <p class="clabel">Service Date</p>
              <p class="cvalue">{{customerInfo.serviceDate}}</p>
          </div>
        </div>
        <div class="my-2">
          <p class="clabel">Payment Methods</p>
          <div *ngIf="paymentErrors.length" class="mb-2">
              <div *ngFor="let e of paymentErrors" class="alert alert-danger d-flex align-items-center" role="alert">
                  <mat-icon class="bi flex-shrink-0 me-2">error_outline</mat-icon>
                  <div>
                      {{e}}
                  </div>
                </div>
          </div>
          <div *ngFor="let p of paymentMethods">
              <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                      <app-payment-method-info [p]="p"></app-payment-method-info>
                  </div>
                  <div class="flex-shrink-0 ms-1">
                      <button mat-icon-button class="menu-icon" (click)="deletePaymentMethod(p)">
                          <mat-icon>delete</mat-icon>
                        </button>
                  </div>
                </div>
          </div>
          <div class="mb-1">
              <button mat-button (click)="addPaymentMethod()">Add Payment Method</button>
          </div>
        </div>
  </form>
  <div class="mb-3">
    <label for="primaryPaymentSelect" class="form-label clabel">Primary Payment Method</label>
     <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'primary')"
      id="primaryPaymentSelect">
        <option value="">Choose...</option>
        <option *ngFor="let item of paymentMethods" [selected]="item.isPrimary === 'True'">{{item.loanPaymentMethodDescription}}</option>
      </select>
  </div>

  <div class="mb-3">
    <label for="primaryPaymentSelect" class="form-label clabel">Secondary Payment Method</label>
     <select class="form-select form-select-sm" (change)="onPaymentChange($event, 'secondary')"
      id="primaryPaymentSelect">
      <option  value="">Choose...</option>
        <option *ngFor="let item of paymentMethods" [selected]="item.isSecondary === 'True'">{{item.loanPaymentMethodDescription}}</option>
      </select>
  </div> -->