<app-dashboard-header></app-dashboard-header>
<p class="h4 m-2">Decision</p>
<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12 col-lg-8 decision-wrap">
            <div class="cds-card mb-2">
                 <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="row">
                                <div class="col-7">
                                    <span>Application Number:</span>
                                </div>
                                <div class="col-5">
                                    <strong>{{csrLoan.loanNumber}}</strong>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <span>Time Stamp:</span>
                                </div>
                                <div class="col-5">
                                    <strong>{{rules.applyDateTime}}</strong>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <span>Decision Rule Set:</span>
                                </div>
                                <div class="col-5">
                                    <strong>{{ruleSetName}}</strong>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <span>Decision Time:</span>
                                </div>
                                <div class="col-5">
                                    <strong>{{ruleSetExecTime}}</strong>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">

                        </div>
                    </div>
                    <div *ngIf="hasFeature('CSR_CUSTOMER_DECISION_RERUN_RULES')" class="d-flex justify-content-end">
                        <div class="m-0">
                            <covered-button type="button" label="Rerun Rules" class="" (click)="rerun()"></covered-button>
                        </div>
                    </div>
                 </div>
            </div>
            <div class="cds-card mb-2">
                <div class="card-body">
                    <!-- <table class="table table-bordered table-sm transaction-table table-hover mb-0">
                        <thead>            
                            <tr>
                                <th>Rule Name</th>
                                <th>Category</th>
                                <th>Reason</th>
                                <th>Decision Type</th>
                                <th>Execution Time</th>
                                <th>Evaluated Expression</th>
                                <th>Outcome</th>
                                <th></th>
                            </tr>            
                        </thead>            
                        <tbody *ngIf="ruleDetails && ruleDetails.length">
                            <tr *ngFor="let r of ruleDetails" [ngClass]="r.ruleCSSClass">
                                <td>{{r.ruleName}}</td>
                                <td>{{r.ruleCategoryName}}</td>
                                <td>{{r.ruleReasonName}}</td>
                                <td>{{r.decisionType}}</td>
                                <td [matTooltip]="r.applyDateTime" matTooltipPosition="above">{{getExecutionSeconds(r.executionTime)}}</td>
                                <td>{{r.expressionDescription}}</td>
                                <td>{{getOutCome(r.success)}}</td>
                                <td [matTooltip]="r.evaluatedExpression" matTooltipPosition="above"><mat-icon class="hand">error_outline</mat-icon></td>
                            </tr>            
                        </tbody>                
                    </table> -->
                    <p-table [value]="ruleDetails" styleClass="p-datatable-lg mr-4" [rowHover]="false" 
                            [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" 
                            [showCurrentPageReport]="false" responsiveLayout="scroll">
                        <ng-template pTemplate="header">
                            <tr>
                                <th scope="col">Rule Name</th>
                                <th scope="col">Category</th>
                                <th scope="col">Reason</th>
                                <th scope="col">Decision Type</th>
                                <th scope="col">Execution Time</th>
                                <th scope="col">Evaluated Expression</th>
                                <th scope="col">Outcome</th>
                                <th scope="col"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-r>
                            <tr [ngClass]="[r.ruleCSSClass]">
                                <td>{{r.ruleName}}</td>
                                <td>{{r.ruleCategoryName}}</td>
                                <td>{{r.ruleReasonName}}</td>
                                <td>{{r.decisionType}}</td>
                                <td [matTooltip]="r.applyDateTime" matTooltipPosition="above">{{getExecutionSeconds(r.executionTime)}}</td>
                                <td>{{r.expressionDescription}}</td>
                                <td>{{getOutCome(r.success)}}</td>
                                <td [matTooltip]="r.evaluatedExpression" matTooltipPosition="above"><mat-icon class="hand">error_outline</mat-icon></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="cds-card">
                <div class="card-body py-3">
                    <div class="row">
                        <mat-tab-group animationDuration="0ms">
                            <mat-tab *ngFor="let r of risk" [label]="r.decisionSource">
                                <p class="h4 mb-1 mt-2">XML</p>
                                <!-- <a *ngIf="r.decisionSource == 'Clarity'" class="m-4" (click)="viewAsTree()" >View Response as Tree</a> -->
                                <div class="mb-2 code-content">
                                    <pre>{{r.decisionXML}}</pre>
                                    <!-- <textarea class="form-control" rows="5">{{r.decisionXML}}</textarea> -->
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-md-12 col-lg-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>