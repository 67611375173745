<h1 mat-dialog-title><strong>{{data.title}}</strong></h1>
<div mat-dialog-content>
<p *ngIf="data.subTitle" class="lead mb-3">{{data.subTitle}}</p>
  <p *ngIf="!data.subTitle" class="lead mb-3">Status change to: <strong>{{data.opt?.desc}}</strong></p>
  <div *ngIf="data.needComment" class="mb-3">
    <textarea class="form-control" rows="2" [formControl]="comment"

    [ngClass]="!comment.pristine && comment.touched && comment.invalid ? 'is-invalid' : ''">
</textarea>
<div *ngIf="!comment.pristine && comment.touched && comment.invalid" class="invalid-feedback">
    Comment required
  </div>
  </div>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-success m-2"(click)="confirm()">Confirm!</button>
    <button type="button" class="btn btn-danger m-2"(click)="cancel()">Cancel</button>
</div>
</div>