import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStorageKeys } from '../configs/app-storage-keys';
import { BranchModel } from '../models/branch-model';
import { CustomerModel } from '../models/customer-model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private _customer = new BehaviorSubject<CustomerModel | null>(null);
  private _customerGuid = new BehaviorSubject<string>('');
  private _branchGuid = new BehaviorSubject<string>('');
  private _branch = new BehaviorSubject<BranchModel | null>(null);
  infoChanged = new Subject<string>();
  infoChanged$ = this.infoChanged.asObservable();
  customer$ = this._customer.asObservable();
  branch$ = this._branch.asObservable();
  customerGuid$ = this._customerGuid.asObservable();
  branchGuid$ = this._branchGuid.asObservable();
  constructor(private apiService: ApiService,
    private router: Router,
    private settings: SettingService,
    private auth: AuthService) { }

  setCustomer(item: CustomerModel) {
    localStorage.setItem(AppStorageKeys.selectedCustomerStorageKey, JSON.stringify(item));
    this._customer.next(item);
  }

  initCustomer(customerGuid: string | undefined) {
    if (customerGuid) {
      this.setCustomerGuid(customerGuid);
    } 
    else {
      var prevGuid = this.getPrevCustomerGuid();
      if (prevGuid) {
        this.router.navigate([`/home/${prevGuid}`]);
      } 
      else {
        var url = `${this.settings.getValue('URL_BOP')}`;
        window.location.href = url;
      }
    }
  }

  getPrevCustomerGuid() {
    var json = localStorage.getItem(AppStorageKeys.selectedCustomerStorageKey);
    if (json) {
      let customer: CustomerModel = JSON.parse(json);
      if (customer && customer.customerGUID) {
        return customer.customerGUID;
      }
    }
    return null;
  }

  async setCustomerGuid(guid: string | undefined | null) {
    if (guid) {
      this._customerGuid.next(guid);
      await this.getCustomer();
    }
  }

  setBranch(item: BranchModel | null) {
    this._branch.next(item);
  }

  setBranchGuid(item: string) {
    this._branchGuid.next(item);
    if (item)
      this.getBranch();
  }

  async getCustomer(): Promise<void> {
    return new Promise((resolve) => {
      this.auth.userGuid$.subscribe((user) => {
        if (user && user.loggedUserGuid) {
          var customerGuid = this._customerGuid.getValue();
          var apiPath = `app/customer/${customerGuid}/bop`
          this.apiService.get(apiPath).subscribe((res: CustomerModel) => {
            this.setCustomer(res);
            this._customerGuid.next(res.customerGUID);
            resolve();
          },
            (err: any) => {
              console.error(err);
              resolve();
            });
        } else resolve();
      });

    });
  }

  getBranch() {
    var branch = this._branch.getValue();
    if (!branch || !branch.branchGuid) {
      this.auth.userGuid$.subscribe((user) => {
        if (user && user.loggedUserGuid) {
          var customerGuid = this._customerGuid.getValue();
          var branchGuid = this._branchGuid.getValue();
          var apiPath = `provider/branch-location/${customerGuid}/${branchGuid}`
          this.apiService.get(apiPath).subscribe(res => {
            this._branch.next(res);
          },
            (err: any) => {
              console.error(err);
            });
        }
      });
    }

  }

  getQueueCounts(): Observable<any> {
    let customerGuid = this.getPrevCustomerGuid();
    if (customerGuid) {
      let apiPath = `csr/queues-count/${customerGuid}`;
      return this.apiService.get(apiPath);
    }
    return of([]);
  }

  getAllCommunications(): Observable<any> {
    let customerGuid = this.getPrevCustomerGuid();
    let apiPath = `csr/communication-readall/${customerGuid}`;
    return this.apiService.get(apiPath);
  }

  getMessageTemplate(templateGuid: any): Observable<any> {
    let customerGuid = this.getPrevCustomerGuid();
    let apiPath = `csr/message-template/${customerGuid}/${templateGuid}`;
    return this.apiService.get(apiPath);
  }
}
