export class CustomerInfoLoanModel {
    accountNumber = "";
    accountNumberFunding = "";
    accountNumberPayments = "";
    additionalJobIncomeType = "-";
    additionalMonthlyIncome = "-";
    address1 = "155 lone";
    address2 = "";
    alimonyVerificationPhone = "-";
    bankName = "-";
    branchLocationID = "";
    branchLocationName = "Corporate";
    campaignID = "1029";
    cellPhone = "-";
    city = "dallas";
    companyName = "-";
    companyPhone = "-";
    country = "US";
    dOB = "08/29/1975";
    emailAddress = "lones@covereduat.com";
    employerIncomeSource = "-";
    employerName = "-";
    employerPhoneNumber = "-";
    firstName = "michelle";
    funding_BankAccountGuid = "";
    homePayLastCheck = "-";
    iPAddress = "-";
    lastName = "none";
    leadPatientID = "test0911";
    middleName = "-";
    occupation = "-";
    payFrequency = "Monthly";
    paymentMethodDescription = "Debit Card";
    paymentMethodID = "7";
    payments_BankAccountGuid = "";
    phone = "972-365-7146";
    plaidLinked = "";
    positionOrDepartment = "-";
    reference1FirstName = "-";
    reference1LastName = "-";
    reference2FirstName = "-";
    reference2LastName = "-";
    routingNumber = "-";
    routingNumberFunding = "-";
    routingNumberPayments = "-";
    sSN = "666-74-9999";
    sSNMasked = "***-**-9999";
    serviceDate = "09/28/2021";
    state = "TX";
    suffix = "-";
    targetID = "4680588";
    workPhone = "-";
    zipCode = "75287";

}