export class SendCommMessageModel {
    CustomerGuid: string | undefined = "";
    CampaignGuid: string | undefined = "";
    TargetGuid: string | undefined = '';
    MessageTypeId = 0;
    MessageCategoryId = 0;
    FileGuid = '';
    FileName = '';
    FileDesc = '';
    MessageGuid: string | null = '';
    EmailSubject = '';
    EmailBody = '';
    EmailTo = '';
    EmailCc = '';
    EmailBcc = '';
    EmailMsgGuidFolder = '';
    AttachmentList = '';
    IsDraft = false;
    SmsPhoneNumber = '';
    SmsMessage = '';
}