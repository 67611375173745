<div class="d-flex justify-content-start">
    <div class="p-1 m-2">
        <covered-button class="outlined" (buttonClick)="back()">
            <i class="pi pi-arrow-left" aria-hidden="true"></i>
        </covered-button>
    </div>
</div>
<div class="cds-card">
    <div class="p-4 flex-auto border-bottom-1 border-black-alpha-20">
        <p class="my-3 text-2xl">{{messageSubject}}</p>
    </div>
    <div class="p-4 flex-auto">
        <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
                <i class="bi bi-person-circle text-5xl text-blue-900" aria-hidden="true"></i>
            </div>
            <div class="flex-grow-1 ml-3">
                <p class="m-0">
                    <strong>
                        {{message.fromFullName}}
                    </strong>
                    <span>
                        {{message.fromEmail}}
                    </span>
                </p>
                <p class="m-0">
                    to {{message.toEmailOrNumber}}
                </p>
            </div>
        </div>
    </div>
    <div class="p-3 flex-auto">
        <div [innerHTML]="emailBody">
        </div>
    </div>
</div>