<div *ngIf="p.loanPaymentMethodType == 'debit'">
    <p class="m-0">
        <strong>
            <span>{{p.paylianceCardNetwork}}</span>
            <span class="ms-1">Debit:</span>
        </strong>
        <span class="ms-1">X-{{p.paylianceCardLastFour}}</span>
        <span class="ms-2">
            <span *ngIf="p.isPrimary === 'True'">Primary Payment Method</span>
            <span *ngIf="p.isSecondary === 'True'">Secondary Payment Method</span>
         </span>
    </p>
    <p>
        <span>Expires</span>
        <span class="ms-1">{{p.paylianceCardExpirationFormatted}}</span>
    </p>
</div>
<div *ngIf="p.loanPaymentMethodType == 'ach'">
    <p class="m-0">
        <strong>{{p.bankInstitutionName}}</strong>
         <span class="ms-2">
            <span *ngIf="p.isPrimary === 'True'">Primary Payment Method</span>
            <span *ngIf="p.isSecondary === 'True'">Secondary Payment Method</span>
         </span>
     </p>
     <p>
        <span>Checking:</span>
        <span class="ms-1">XXXX-{{p.bankAccountNumberLast4}}</span>
    </p>
</div>