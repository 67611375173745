<h1 mat-dialog-title>Customer Email Changes</h1>
<div mat-dialog-content>
    <div class="mb-3">
        <label for="emailInput" class="form-label">Confirm Code</label>
        <input type="text" class="form-control"
        [ngClass]="confirmCode && confirmCode.touched && confirmCode.invalid ? 'is-invalid' : ''" 
        id="emailInput" 
        style="text-transform:uppercase"
        placeholder="Code" [formControl]="confirmCode">
        <div *ngIf="confirmCode && confirmCode.touched && confirmCode.invalid" class="invalid-feedback">
            Confirm Code is required
          </div>
      </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onClick('back')" color="primary">Back</button>
    <button mat-flat-button (click)="onClick('confirmed')" color="primary">CONFIRM</button>
</div>