<div class="mb-3">
  <label for="fileName" class="form-label">Name the Document:</label>
  <input type="text" class="form-control" id="fileName" style="text-transform:uppercase" [formControl]="fileName">
</div>
<div class="card upload-card" (click)="uploader.click()">
  <div class="card-body">
    <input hidden type="file" #uploader (change)="uploadFileInput($event)" />
  </div>
  <div class="m-3 p-3 text-center" appUpload (onFileDropped)="uploadFile($event)">Drop a file here</div>
  <div class="p-2" *ngIf="uploadedFile">
    <div class="d-flex align-items-center">
      <div class="flex-shrink-0">
        <img [src]="iconPath" alt="..." width="50px" height="50px">
      </div>
      <div class="flex-grow-1 ms-3">
        {{ uploadedFile }}
      </div>
    </div>
  </div>
</div>
<div class="pt-3">
  <covered-button (buttonClick)="back()" label="Back" class="outline mr-3" [disabled]="uploading"></covered-button>
  <covered-button (buttonClick)="upload()" label="Upload" class="w-4" [disabled]="uploading"></covered-button>
</div>