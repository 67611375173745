import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-leads-page',
  templateUrl: './leads-page.component.html',
  styleUrls: ['./leads-page.component.scss']
})
export class LeadsPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private guidService: GuidService,
    private customerService: CustomerService
    ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
      }
    });
  }

  rowSelected(row: any) {
    var url = `/dashboard/${row.CustomerGUID}/${row.CampaignGUID}/${row.TargetGUID}`;
    window.open(url, "_blank");    
  }
}
