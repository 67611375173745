<div *ngIf="!selectedMessage">
  <div class="d-flex mb-3 w-100">
    <div class="me-auto p-1">
      <p class="h5 m-1">{{ mailboxLabel }}</p>
      <p class="m-1">{{ label }} ({{ filteredMessages.length }})</p>
    </div>
    <div class="p-1">
      <div class="m-0">
        <covered-form-text id="frmSearch" placeholder="Search" [(ngModel)]="searchStr"
          (keyupEvent)="searchCtrl.setValue($event)"></covered-form-text>
      </div>
    </div>
  </div>
  <div class="flex flex-row-reverse">
    <div class="p-1">
      <button pButton class="p-button p-button-outlined" 
        (click)="opFilter.toggle($event)">
        <div class="m-2 pt-2">
          <i class="pi pi-filter-fill text-xl pr-2" aria-hidden="true"></i>
          <i class="pi pi-angle-down text-xl" aria-hidden="true"></i>
        </div>
      </button>
      <p-overlayPanel #opFilter [showCloseIcon]="false" [showTransitionOptions]="'0.2s'">
        <ng-template pTemplate>
            <div class="h-auto">
                <div *ngFor="let f of filters" class="comm-filter-item" (click)="setFilterType(f);opFilter.hide();">
                  <p-badge *ngIf="f.class" [styleClass]="f.class"></p-badge>
                  <span>{{ f.text }}</span>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
    </div>
    <div class="p-1">
      <covered-button class="outlined" (buttonClick)="refresh()">
        <div class="m-2 pt-2">
          <i class="pi pi-replay text-xl" aria-hidden="true"></i>
        </div>
      </covered-button>
    </div>
  </div>
  <div class="flex flex-column messages" *ngIf="messages.length">
    <div class="inbox-item" *ngFor="let m of filteredMessages"
      (click)="select(m)">
      <div class="flex">
        <div class="p-2 w-100">
          <p>
            <span class="comm-msg-badge">
              <p-badge [styleClass]="getItemBadgeStyle(m)"></p-badge>
            </span>
            <span class="mr-1 message-subject">{{m.messageSubject}}</span>
            <span class="message-text m-1">
              {{m.messageText || m.objectBody}}
            </span>
          </p>
        </div>
        <p class="p-2 m-0 flex-shrink-1 message-time">
          {{m.sendReceivedDate}} CT
        </p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedMessage">
  <app-comm-mailbox-item [message]="selectedMessage" (onBack)="backToInbox()"></app-comm-mailbox-item>
</div>