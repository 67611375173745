<div class="cds-card cs-profile-layout">
  <div class="py-4 pl-6 text-2xl font-medium">
    My Profile
  </div>
  <div class="p-4" *ngIf="loading"><covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar></div>
  <div *ngIf="!loading" class="p-4">
    <form [formGroup]="profileForm" (ngSubmit)="submit()">
      <div class="w-12 px-4 pb-4 flex flex-row">
      <div class="w-6 mr-4">
        <covered-form-text formControlName="firstName" id="firstname" placeholder="First Name"></covered-form-text>
      </div>
      <div class="w-6 flex-grow-1">
        <covered-form-text formControlName="lastName" id="lastname" placeholder="Last Name"></covered-form-text>
      </div>
    </div>
      <div class="w-12 px-4 pb-4">
        <covered-form-text formControlName="company" id="company" placeholder="Company" [disabled]="true"></covered-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <covered-form-text formControlName="email" id="email" placeholder="Email" [disabled]="true"></covered-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <covered-form-text formControlName="cellPhone" id="cellphone" placeholder="Cell Phone"></covered-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <covered-form-text formControlName="workPhone" id="workphone" placeholder="Work Phone"></covered-form-text>
      </div>
      <div class="w-12 px-4 pb-4">
        <covered-dropdown formControlName="timeZoneId" id="timezone" placeholder="Time Zone" [items]="timeZones" optionLabel="desc" optionValue="id"></covered-dropdown>
      </div>
      <div class="col-offset-9 col-3 pr-4">
        <covered-button buttonType="submit" label="Save" class="p-button-lg w-12"></covered-button>
      </div>
    </form>
  </div>
</div>

<covered-toast [key]="toastKey" [position]="toastPos"></covered-toast>

<!-- <p-dialog [modal]="true" [(visible)]="showSuccess" [closable]="false">
  <app-success header="Profile Updated Successfully" (okClick)="showSuccess = false;"></app-success>
</p-dialog> -->