import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { PaymentMethodModel } from 'src/app/models/payment-methods';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { phoneRegex, ssnRegex } from 'src/app/services/util.service';
import { fieldChanged } from '../confirm-changes/confirm-changes.component';

@Component({
  selector: 'app-customer-info-edit',
  templateUrl: './customer-info-edit.component.html',
  styleUrls: ['./customer-info-edit.component.scss']
})
export class CustomerInfoEditComponent implements OnInit {
  customerInfo: CustomerInfoLoanModel = new CustomerInfoLoanModel;
  paymentMethods: PaymentMethodModel[] = [];
  loanForm: FormGroup = new FormGroup({});
  ids: ActivatedIds = {};
  changes: fieldChanged[] = [];
  values: any[] = [];
  paymentErrors: string[] = [];
  paymentMethodTouched = false;
  @Output() updated = new EventEmitter<boolean>();
  constructor(private dashboardService: DashboardService,
    private main: MainService,
    private api: ApiService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dashboardService.loanData$.subscribe(loan => {
      if (loan != null) {
        this.customerInfo = loan;
        this.initForm();
      }

    });
    this.dashboardService.paymentMethods$.subscribe(p => {
      this.paymentMethods = p;
      this.validatePaymentMethods();
    });

    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid) {
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
          this.ids = ids;
        }
      }
    });
  }

  initForm() {
    this.loanForm = this.fb.group({
      firstName: [this.customerInfo.firstName, Validators.required],
      lastName: [this.customerInfo.lastName, Validators.required],
      middleName: [this.customerInfo.middleName],
      address1: [this.customerInfo.address1, Validators.required],
      address2: [this.customerInfo.address2],
      city: [this.customerInfo.city, Validators.required],
      zipCode: [this.customerInfo.zipCode, Validators.required],
      state: [this.customerInfo.state, Validators.required],
      phone: [this.customerInfo.phone, Validators.pattern(phoneRegex)],
      cellPhone: [this.customerInfo.cellPhone, Validators.pattern(phoneRegex)],
      sSN: [this.customerInfo.sSN, [Validators.pattern(ssnRegex), Validators.required]],
      reference1FirstName: [this.customerInfo.reference1FirstName],
      reference1LastName: [this.customerInfo.reference1LastName],
      reference2FirstName: [this.customerInfo.reference2FirstName],
      reference2LastName: [this.customerInfo.reference2LastName],
    });
  }

  editEmail() {
    this.main.showEmailChangeModal().afterClosed().subscribe(result => {
      if (result && result !== 'back') {
        this.main.showLoading();
        var model = this.getTargetModel(`request|${result}`);
        var body = JSON.stringify(model);
        this.api.postStringResponse(`csr/update-email`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (res == "Success") {
              this.showConfirmEmailCode();
            } else {
              this.main.showErrorModal("Oops...", "Error occured");
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              var msg = err.error.text || "Error occured";
              this.main.showErrorModal("Oops...", msg);
            }
          );
      }
    });
  }

  showConfirmEmailCode() {
    this.main.showConfirmEmailChangeModal().afterClosed().subscribe(result => {
      if (result && result !== 'back') {
        this.main.showLoading();
        var model = this.getTargetModel(`confirm|${result}`);
        var body = JSON.stringify(model);
        this.api.postStringResponse(`csr/update-email`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (res && res.toLowerCase().indexOf('invalid code') !== -1) {
              this.main.showErrorModal("Oops...", res).afterClosed().subscribe(() => {
                this.showConfirmEmailCode();
                return;
              })
            }
            if (this.main.showResponse(res || "Customer Email Updated!")) {
              this.updated.emit(true);
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              var msg = err.error.text || "Error occured";
              this.main.showErrorModal("Oops...", msg);
            }
          );
      }
    });
  }

  saveInfo() {
    this.changes = [];
    this.values = [];
    if (!this.loanForm.valid) {
      this.loanForm.markAllAsTouched();
      this.loanForm.markAsDirty();
      return;
    }

    if (this.paymentErrors.length) {
      return;
    }

    this.main.showLoading();
    this.values.push({ "name": "First Name", "value": this.loanForm.value.firstName });
    this.addChanges("First Name", this.customerInfo.firstName, this.loanForm.value.firstName);

    this.values.push({ "name": "Middle Name", "value": this.loanForm.value.middleName });
    this.addChanges("Middle Name", this.customerInfo.middleName, this.loanForm.value.middleName);

    this.values.push({ "name": "Last Name", "value": this.loanForm.value.lastName });
    this.addChanges("Last Name", this.customerInfo.lastName, this.loanForm.value.lastName);

    // values.push({ "name": "Date Of Birth Full", "value": this.loanForm.value.address1 });
    // this.addChanges("", this.customerInfo.firstName, this.loanForm.value.firstName);
    this.values.push({ "name": "Address Line 1", "value": this.loanForm.value.address1 });
    this.addChanges("Address Line 1", this.customerInfo.address1, this.loanForm.value.address1);

    this.values.push({ "name": "Address Line 2", "value": this.loanForm.value.address2 });
    this.addChanges("Address Line 2", this.customerInfo.address2, this.loanForm.value.address2);

    this.values.push({ "name": "City", "value": this.loanForm.value.city });
    this.addChanges("City", this.customerInfo.city, this.loanForm.value.city);

    this.values.push({ "name": "State", "value": this.loanForm.value.state });
    this.addChanges("State", this.customerInfo.state, this.loanForm.value.state);

    this.values.push({ "name": "Zip Code", "value": this.loanForm.value.zipCode });
    this.addChanges("Zip Code", this.customerInfo.zipCode, this.loanForm.value.zipCode);

    this.values.push({ "name": "Cell Phone", "value": this.loanForm.value.cellPhone });
    this.addChanges("Cell Phone", this.customerInfo.cellPhone, this.loanForm.value.cellPhone);

    this.values.push({ "name": "Phone", "value": this.loanForm.value.phone });
    this.addChanges("Phone", this.customerInfo.phone, this.loanForm.value.phone);

    this.values.push({ "name": "SSN", "value": this.loanForm.value.sSN });
    this.addChanges("SSN", this.customerInfo.sSN, this.loanForm.value.sSN);

    this.values.push({ "name": "Reference 1 First Name", "value": this.loanForm.value.reference1FirstName });
    this.addChanges("Reference 1 First Name", this.customerInfo.reference1FirstName, this.loanForm.value.reference1FirstName);

    this.values.push({ "name": "Reference 1 Last Name", "value": this.loanForm.value.reference1LastName });
    this.addChanges("Reference 1 Last Name", this.customerInfo.reference1LastName, this.loanForm.value.reference1LastName);

    this.values.push({ "name": "Reference 2 First Name", "value": this.loanForm.value.reference2FirstName });
    this.addChanges("Reference 2 First Name", this.customerInfo.reference2FirstName, this.loanForm.value.reference2FirstName);

    this.values.push({ "name": "Reference 2 Last Name", "value": this.loanForm.value.reference2LastName });
    this.addChanges("Reference 2 Last Name", this.customerInfo.reference2LastName, this.loanForm.value.reference2LastName);

    this.confirmChanges();
  }

  private confirmChanges() {
    this.main.dismissLoading();
    if (this.changes.length) {
      this.main.showConfirmChanges(this.changes).afterClosed().subscribe(result => {
        if (result == "confirmed") {
          if (this.addressChanged()) {
            this.validateAddress();
          } else {
            this.postChanges();
          }
        }
      })
    }
  }

  private postChanges() {
    this.main.showLoading();
    var model = this.getTargetModel(JSON.stringify({ "values": this.values }));
    var body = JSON.stringify(model);
    this.api.postStringResponse(`csr/update-customer`, body)
      .subscribe((res: any) => {
        this.postPaymentMethodChanges(res);
      },
        (err: any) => {
          this.main.dismissLoading();
          var msg = err.error.text || "Error occured";
          this.main.showErrorModal("Oops...", msg);
        }
      );
  }

  postPaymentMethodChanges(res: any) {
    if (!this.paymentMethodTouched) {
      this.main.dismissLoading();
      if (this.main.showResponse(res)) {
        this.updated.emit(true);
      }
      return;
    }
    var primary = '';
    var secondary = '';
    this.paymentMethods.forEach(p => {
      if (p.isPrimary == 'True') {
        primary = p.loanPaymentMethodGUID;
      }
      if (p.isSecondary == 'True') {
        secondary = p.loanPaymentMethodGUID;
      }
    });
    if (primary && secondary) {
      var model = this.getTargetModel(`${primary}|${secondary}`);
      var body = JSON.stringify(model);
      this.api.postStringResponse(`csr/set-primary-sec-payments`, body)
        .subscribe((res: any) => {
          this.main.dismissLoading();
          if (this.main.showResponse(res)) {
            this.updated.emit(true);
          }
        },
          (err: any) => {
            this.main.dismissLoading();
            var msg = err.error.text || "Error occured updating paymemt methods";
            this.main.showErrorModal("Oops...", msg);
          }
        );
    }
  }

  private addChanges(field: string, from: string, to: string) {
    if (!this.changes.length) {
      this.changes = [];
    }

    if (from !== to) {
      this.changes.push({
        field, from: from, to: to
      });
    }
  }

  private validateAddress() {
    this.main.showLoading();
    var model = {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Address1: this.loanForm.value.address1,
      Address2: this.loanForm.value.address2,
      City: this.loanForm.value.city,
      State: this.loanForm.value.state,
      ZipCode: this.loanForm.value.zipCode
    }
    var body = JSON.stringify(model);
    this.api.post(`csr/validate-address`, body)
      .subscribe((res: any) => {
        this.main.dismissLoading();
        if (res) {
          if (res.hasCorrection == "True") {
            var entered = `${this.loanForm.value.address1}, ${this.loanForm.value.address2}, ${this.loanForm.value.city}, ${this.loanForm.value.state}, ${this.loanForm.value.zipCode}`;
            var correction = `${res.address1}, ${res.address2}, ${res.city}, ${res.state}, ${res.zipCode}`;
            this.main.showAddressCorrection(entered.replace(', ,', ','), correction.replace(', ,', ',')).afterClosed().subscribe(action => {
              if (action === 'confirmed') {
                this.updateValues("Address Line 1", res.address1);
                this.updateValues("Address Line 2", res.address2);
                this.updateValues("City", res.city);
                this.updateValues("State", res.state);
                this.updateValues("Zip Code", res.zipCode);
                this.postChanges();
              }
            })
          } else {
            this.postChanges();
          }
        }
      },
        (err: any) => {
          this.main.showErrorModal("Oops...", "Customer Address could not be validated");
        }
      );
  }

  private addressChanged() {
    if (this.loanForm.value.address1 !== this.customerInfo.address1 ||
      this.loanForm.value.address2 !== this.customerInfo.address2 ||
      this.loanForm.value.city !== this.customerInfo.city ||
      this.loanForm.value.state !== this.customerInfo.state ||
      this.loanForm.value.zipCode !== this.customerInfo.zipCode)
      return true;

    return false;
  }

  updateValues(name: string, value: string) {
    for (var i = 0; i < this.values.length; i++) {
      if (this.values[i].name == name) {
        this.values[i].value = value;
        return;
      }
    }
  }

  deletePaymentMethod(p: PaymentMethodModel) {
    this.main.showYesNoPopUp("Delete Payment Method",  p.loanPaymentMethodDescription, "CONFIRM",
      "Cancel", "500px","Are you sure you want to delete this Payment Method?")
      .afterClosed().subscribe(result => {
        if (result == 'yes'){
          this.main.showLoading();
          var model = this.getTargetModel(p.loanPaymentMethodGUID);
          var body = JSON.stringify(model);
          this.api.postStringResponse(`csr/delete-payment-method`, body)
            .subscribe((res: any) => {
              this.main.dismissLoading();
              if (this.main.showResponse(res)) {
                this.updated.emit(true);
              }
            },
              (err: any) => {
                this.main.dismissLoading();
                var msg = err.error.text || "Error occured";
                this.main.showErrorModal("Oops...", msg);
              }
            );
        }
      })
  }

  addPaymentMethod() {
    this.main.showAddPaymentMethod().afterClosed().subscribe(result => {
      if (result) {
        this.main.showLoading();
        var body = JSON.stringify(result);
        this.api.postStringResponse(`csr/update-payment-method`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (this.main.showResponse(res)) {
              this.updated.emit(true);
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              var msg = err.error.text || "Error occured";
              this.main.showErrorModal("Oops...", msg);
            }
          );
      }
    });
  }

  onPaymentChange(ev: any, typ: string) {
    this.paymentMethodTouched = true;
    var value = ev.target.value;
    if (value) {
      if (typ === 'primary') {
        this.paymentMethods.forEach(p => {
          p.isPrimary = 'False';
          if (p.loanPaymentMethodDescription == value) {
            p.isPrimary = 'True';
            p.isSecondary = 'False';
          }
        })
      }

      if (typ === 'secondary') {
        this.paymentMethods.forEach(p => {
          p.isSecondary = 'False';
          if (p.loanPaymentMethodDescription == value) {
            p.isSecondary = 'True';
            p.isPrimary = 'False';
          }
        })
      }

    }
    this.validatePaymentMethods();
  }

  validatePaymentMethods() {
    this.paymentErrors = [];
    var primary = '';
    var secondary = '';
    var cardExpired = false;
    this.paymentMethods.forEach(p => {
      if (p.isPrimary == 'True') {
        primary = p.loanPaymentMethodGUID;
        if (p.expired == 'True') {
          cardExpired = true;
        }
      }
      if (p.isSecondary == 'True') {
        secondary = p.loanPaymentMethodGUID;
        if (p.expired == 'True') {
          cardExpired = true;
        }
      }
    });
    if (!primary || !secondary) {
      this.paymentErrors.push('You must have a primary and secondary payment method selected.');
    } else {
      if (primary == secondary) {
        this.paymentErrors.push('"Your primary and secondary payment methods must be different.');
      }
    }
    if (cardExpired) {
      this.paymentErrors.push('Your card has expired. Please update by adding your new card information, then deleting the expired card.');
    }

  }

  private getTargetModel(val: string){
    return {
      CustomerGuid: this.ids.customerGuid,
      CampaignGuid: this.ids.campaignGuid,
      TargetGuid: this.ids.targetGuid,
      Value: val
    }
  }
}
