import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CsrLookupModel } from '../models/csr-lookup-model';
import { CustomerInfoLoanModel } from '../models/customer-info-loan-model';
import { PaymentMethodModel } from '../models/payment-methods';
import { TargetInfoModel } from '../models/target-info-model';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _loanData = new BehaviorSubject<CustomerInfoLoanModel | null>(null);
  private _paymentMethods = new BehaviorSubject<PaymentMethodModel[]>([]);
  private _targetInfo = new BehaviorSubject<TargetInfoModel>(new TargetInfoModel);
  private _lookups = new BehaviorSubject<CsrLookupModel>(new CsrLookupModel);
  lookups$ = this._lookups.asObservable();
  loanData$ = this._loanData.asObservable();
  targetInfo$ = this._targetInfo.asObservable();
  paymentMethods$ = this._paymentMethods.asObservable();
  
  constructor(
    private custService: CustomerService, 
    private apiService: ApiService
    ) { }

  setLoanData(loanData: CustomerInfoLoanModel){
    this._loanData.next(loanData);
  }

  setPaymentMethods(data: PaymentMethodModel[]){
    this._paymentMethods.next(data);
  }

  setTargetInfo(data: TargetInfoModel){
    this._targetInfo.next(data);
  }

  setLookup(data: CsrLookupModel){
    this._lookups.next(data);
  }

  
}
