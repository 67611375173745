<div class="payOff-width">
    <p-table [value]="payments" styleClass="p-datatable-lg mr-4" [rowHover]="true"
    [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" 
    [showCurrentPageReport]="false" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th class="font-medium" scope="col">TODAY</th>
            <th class="font-medium" scope="col">{{todayDate}}</th>
            <th class="font-medium" scope="col">{{payoffBalance}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-h>
        <tr class="cursor-pointer">
            <td>{{ h.weekday }}</td>
            <td>{{ h.date }}</td>
            <td>{{ h.payOff }}</td>
        </tr>
    </ng-template>
</p-table>

<p class="text-center py-3">
    <covered-button type="button" (click)="btnClick.emit()">OKAY</covered-button>
</p>
</div>