import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { GuidService } from 'src/app/services/guid.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-agent-tools-page',
  templateUrl: './agent-tools-page.component.html',
  styleUrls: ['./agent-tools-page.component.scss']
})
export class AgentToolsPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute, 
    private customerService: CustomerService,
    private guidService: GuidService,
    private userService: UserService
    ) { }

  customerGuid: any;
  campaignGuid: any;
  targetGuid: any;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }        
      }
      let campGuid = paramMap.get('campaignGuid')?.toString() ?? '';
      let tGuid = paramMap.get('targetGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(campGuid)) this.campaignGuid = campGuid;
      if (this.guidService.isValidGuid(tGuid)) this.targetGuid = tGuid;
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  courtesyAdjustmentLink() {
    window.open("/courtesy-adjustment/" + this.customerGuid + "/" + this.campaignGuid + "/" + this.targetGuid + "","_self");
  }
  manualPaymentsLink() {
    window.open("/manual-payments/" + this.customerGuid + "/" + this.campaignGuid + "/" + this.targetGuid + "", "_self");
  }
  collectionsLink() {
    // csr_customer_collections.aspx?customerGuid=<%= Master.CustomerGuid %>&campaignGuid=<%= Master.CampaignGuid %>&targetGuid=<%= Master.TargetGuid %>
    
    window.open("", "_self");
  }


}
