<app-dashboard-header></app-dashboard-header>
<div class="row">
    <div class="col-md-12 col-lg-8">
        <covered-dropdown formControlName="selectedPage" optionLabel="pageName" id="selectedPage" placeholder="" [items]="pages" (optionSelected)="pageSelected($event)"></covered-dropdown>
        <iframe #campaignFrame id="idIframe" (load)="iFrameLoaded($event)" [src]="iframeUrl" style="width: 100%;" title="Campaign"></iframe>
        <covered-dropdown formControlName="selectedPage" optionLabel="pageName" id="selectedPage" placeholder="" [items]="pages" (optionSelected)="pageSelected($event)"></covered-dropdown>
    </div>
    <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-md-12 col-lg-4">
        <app-customer-notes></app-customer-notes>
    </div>
</div>

