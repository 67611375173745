import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedIds} from 'src/app/models/activated-ids';
import {IMenuLink} from 'src/app/models/imenu-link';
import {LookupModel} from 'src/app/models/lookup-model';
import {MainService} from 'src/app/services/main.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() expanded = false;
  menus: IMenuLink[] = [];
  adminRoles: LookupModel[] = [];
  ids: ActivatedIds = {};

  constructor(private router: Router, private main: MainService) {
  }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(ids => {
      this.ids = ids;
    });
    this.setMenus();
  }

  setMenus() {
    var params = `/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`;
    this.menus = [
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Dashboard',
        url: `/dashboard${params}`,
        icon: 'grid_view',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Pre Application',
        url: `/pre-application${params}`,
        icon: 'badge',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Application',
        url: `/application${params}`,
        icon: 'source',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Decision',
        url: `/decision${params}`,
        icon: 'assessment',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Account Servicing',
        url: `/account-servicing${params}`,
        icon: 'folder',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Communications',
        url: `/communications${params}`,
        icon: 'mark_as_unread',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Agent Tools',
        url: `/agent-tools${params}`,
        icon: 'headset_mic',
        children: []
      },
      {
        show: false,
        id: 'Client',
        selected: false,
        title: 'Systems',
        url: `/systems${params}`,
        icon: 'build',
        children: []
      },
    ]
  }

  menuSelected(menu: IMenuLink) {
    this.menus.forEach((menu) => {
      menu.selected = false;
    })
    for (var i = 0; i < this.menus.length; i++) {
      if (this.menus[i].title === menu.title) {
        this.menus[i].selected = true;
        break;
      }
    }
    this.router.navigate([menu.url]);
  }
}
