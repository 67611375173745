<app-dashboard-header></app-dashboard-header>
<p class="h4 m-2">Account Servicing</p>
<div *ngIf="!loading">
    <div class="row">
        <div class="col-md-12 col-lg-8">
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="cds-card p-3 mb-2">
                        <div class="card-body">
                            <div class="text-center">
                                <p class="h5 mt-2 mb-0">Account Snapshot</p>
                                <p class="mb-2">#{{accountSummary.loanNumber}}</p>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="d-flex mb-0">
                                <div class="p-2">Contract Amount</div>
                                <div class="ms-auto p-2">${{accountSummary.loanAmount}}</div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="d-flex mb-0">
                                <div class="p-2">Contract Balance</div>
                                <div class="ms-auto p-2">${{accountSummary.currentPrincipalBalance}}</div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="d-flex mb-0">
                                <div class="p-2">Finance Charges</div>
                                <div class="ms-auto p-2">${{accountSummary.interestBalance}}</div>
                            </div>
                            <div *ngIf="lateFees">
                                <mat-divider></mat-divider>
                                <div class="d-flex mb-0">
                                    <div class="p-2">Late Charge</div>
                                    <div class="ms-auto p-2">${{lateFees}}</div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="d-flex mb-0">
                                <div class="p-2"><strong>Total Account Balance</strong></div>
                                <div class="ms-auto p-2">${{accountSummary.totalAccountBalance}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="cds-card mb-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <div class="m-1">
                                    <covered-button type="button" class="p-button-text p-button-text" (click)="showLoanAreementPdf()" label="My Credit Contract"></covered-button>

                                </div>
                                <div class="m-3">|</div>
                                <div class="m-1">
                                    <covered-button type="button" class="p-button-text p-button-text" (click)="showWelcomePacket()" label="Welcome Packet"></covered-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cds-card mb-2">
                        <div class="card-body">
                            <div class="d-flex justify-content-center">
                                <div class="m-1">
                                    <covered-button type="button" class="p-button-text p-button-text" (click)="showPayments = true;" label="Upcoming Payments"></covered-button>


                                </div>
                                <div class="m-3">|</div>
                                <div class="m-1">
                                    <covered-button type="button" class="p-button-text p-button-text" (click)="showPayoffs = true;" label="10 Day Payoff"></covered-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <mat-accordion class="payment-panel mb-3">
                         <mat-expansion-panel hideToggle [expanded]="!showScheduleEdit" (opened)="showScheduleEdit = false" class="chris">
                     
                            <div class="">
                                <div class="card-body">
                                    <p class="text-center h5 my-2">Payments</p>
                                    <div *ngIf="accountSummary.upcomingPaymentScheduledPopup === '1' || accountSummary.upcomingPaymentScheduledPopup === 'True'"
                                        class="alert alert-info d-flex align-items-center alert-purple" role="alert">
                                        <i class="pi pi-exclamation-triangle" style="font-size: 2rem" aria-hidden="true"></i>
                                        <div>
                                            {{accountSummary.upcomingPaymentScheduledMessage}}
                                        </div>
                                    </div>
                                    <div class="m-1">
                                        <div class="p-2"><strong>Scheduled Payment</strong></div>
                                        <div *ngIf="scheduledPayments && scheduledPayments.length">
                                            <div *ngFor="let p of scheduledPayments">
                                                <mat-divider></mat-divider>
                                                <div class="d-flex mb-0">
                                                    <div class="p-2" 
                                                    [ngStyle]="{'color': p.descriptionColor}"
                                                    [ngClass]="p.pushedPayment == '1' || p.pushedPayment == 'True' ? 'text-muted' : ''">
                                                    {{p.description}}
                                                </div>
                                                    <div class="ms-auto p-2" [ngClass]="p.pushedPayment == '1' || p.pushedPayment == 'True' ? 'text-muted' : ''">${{p.totalAmt | formatMoney}}</div>
                                                </div>
                                                <mat-divider></mat-divider>
                                                <div class="d-flex mb-0">
                                                    <div class="p-2">Payment Due Date</div>
                                                    <div class="ms-auto p-2">{{p.dateDueFormatted}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="d-flex mb-0">
                                            <div class="p-2"><strong>Pay in Full Amount</strong></div>
                                            <div class="ms-auto p-2"><strong>${{accountSummary.totalAccountBalance | formatMoney}}</strong></div>
                                        </div>
                                    </div>
                                    <div *ngIf="hasFeature('CSR_CUSTOMER_ACCOUNT_SERVICING_SEND_MAKE_PMT_LINK')" class="m-1">
                                        <div class="p-2"><strong>Quick Pay</strong></div>
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <covered-button type="button" (click)="copyLink()" label="Get Link" 
                                                            class="p-button-text p-button-text" [cdkCopyToClipboard]="accountSummary.firstTimePaymentURL"></covered-button>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <covered-button type="button" (click)="sendSms()" label="Send SMS" class="p-button-text p-button-text"></covered-button>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <covered-button type="button" (click)="sendEmail()" label="Send Email" class="p-button-text p-button-text"></covered-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="hasFeature('CSR_CUSTOMER_ACCOUNT_SERVICING_MAKE_PAYMENT')" class="m-2 py-4">
                                        <div class="text-center">
                                            <covered-button
                                                class="p-button-lg px-8 py-2"
                                                label="SCHEDULE A PAYMENT"
                                                icon="pi pi-search"
                                                (click)="makePayment()"
                                            ></covered-button>
                                        </div>
                                    </div>
                                </div>  
                            </div>

                        </mat-expansion-panel>
                        <mat-expansion-panel hideToggle [expanded]="showScheduleEdit" (opened)="showScheduleEdit = true" class="chris2">
                            <app-schedule-payment-form 
                            (onBack)="onBack($event)" 
                            [paymentAccounts]="paymentAccounts"
                            [processors]="processors"
                            [accSummary]="accountSummary">
                        </app-schedule-payment-form>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            <div class="cds-card">
                <div class="card-body">
                    <p class="text-center h5 my-2">Transaction History</p>
                    
                    <p-table [value]="transactionHistory" styleClass="p-datatable-lg mr-4" [rowHover]="true"
                        [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" 
                        [showCurrentPageReport]="false" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="font-medium" scope="col">Date</th>
                            <th class="font-medium" scope="col">Description</th>
                            <th class="font-medium" scope="col">Pmt Amt</th>
                            <th class="font-medium" scope="col">Prin</th>
                            <th class="font-medium" scope="col">Finance Charges</th>
                            <th class="font-medium" scope="col">Contract Bal</th>
                            <th class="font-medium" scope="col">Balance</th>
                            <th class="font-medium" scope="col">DPD</th>
                            <th class="font-medium" scope="col">Type</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-h>
                        <tr class="cursor-pointer">
                            <td>{{ h.tranDate }}</td>
                            <td>{{ h.tranDescription }}</td>
                            <td>{{ h.payment }}</td>
                            <td>{{ h.principal }}</td>
                            <td>{{ h.fees }}</td>
                            <td>{{ h.balance }}</td>
                            <td>{{ h.principalBalance }}</td>
                            <td>{{ h.dPD }}</td>
                            <td>{{ h.tender }}</td>
                        </tr>
                    </ng-template>
                </p-table>
                </div>
            </div>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-md-12 col-lg-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>

<p-dialog [modal]="true" [(visible)]="showPayments" [closable]="true">
    <app-upcoming-payments [payments]="upcomingPayments" (btnClick)="showPayments = false;"></app-upcoming-payments>
</p-dialog>
<p-dialog [modal]="true" [(visible)]="showPayoffs" [closable]="true">
    <app-ten-day-payoff [payments]="tenDaysPayoffs" [payoffBalance]="accountSummary.totalAccountBalance" (btnClick)="showPayoffs = false;"></app-ten-day-payoff>
</p-dialog>