import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CsrNotificationItem } from 'src/app/models/csr-notification-item';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-customer-notification',
  templateUrl: './add-customer-notification.component.html',
  styleUrls: ['./add-customer-notification.component.scss']
})
export class AddCustomerNotificationComponent implements OnInit {
  notificationText = new FormControl('', Validators.required);
  ids: ActivatedIds = new ActivatedIds;
  notifications: CsrNotificationItem [] = [];
  loading = true;
  updating = false;
  constructor(private main: MainService, 
    private userService: UserService,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.main.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid){
        this.ids = id;
        this.getNotifications();
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  getNotifications(){
    this.loading = true;
    this.apiService.get(`csr/notifications/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
    .subscribe((res: any) => {
      this.loading = false;
      this.notifications = res || [];
    },
      (err: any) => {
        this.loading = false;
        console.error(err);
      }
    );
  }

  getNotificationClass(priority: string){
    switch(priority) {
      case "1":
        return 'priority-high';
      case "2":
        return 'priority-medium';
      default:
        return 'priority-none';
    }
  }

  add(priority: string){
   
    if (this.notificationText.invalid){
      this.notificationText.markAsTouched();
      return;
    }

    var body = JSON.stringify(this.notificationText.value);
    this.updating = true;
    this.apiService.post(`csr/add-notification/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}/${priority}`, body)
      .subscribe((res: any) => {
        this.updating = false;
        this.notificationText.setValue('')
        this.notificationText.markAsUntouched();
        this.getNotifications();
      },
        (err: any) => {
          this.updating = false;
          this.getNotifications();
        }
      );
  }
}
