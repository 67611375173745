<div class="flex flex-column mb-3">
    <p class="text-5xl m-3">Search Results</p>
    <covered-progress-bar *ngIf="searching" [style]="{'height': '0.5rem'}"></covered-progress-bar>

    <div *ngIf="!searching" class="cds-card pl-5 mr-5">
        <p-table [value]="queues" styleClass="p-datatable-lg mr-4"
        [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first" 
        [showCurrentPageReport]="false" responsiveLayout="scroll" [rowHover]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="accountNumber" class="font-medium" scope="col">Account Number <p-sortIcon field="accountNumber"></p-sortIcon></th>
                <th pSortableColumn="contractLimit" class="font-medium" scope="col">Contract Limit <p-sortIcon field="contractLimit"></p-sortIcon></th>
                <th pSortableColumn="firstName" class="font-medium" scope="col">First Name <p-sortIcon field="firstName"></p-sortIcon></th>
                <th pSortableColumn="lastName" class="font-medium" scope="col">Last Name <p-sortIcon field="lastName"></p-sortIcon></th>
                <th pSortableColumn="phone" class="font-medium" scope="col">Mobile <p-sortIcon field="phone"></p-sortIcon></th>
                <th pSortableColumn="city" class="font-medium" scope="col">City <p-sortIcon field="city"></p-sortIcon></th>
                <th pSortableColumn="state" class="font-medium" scope="col">State <p-sortIcon field="state"></p-sortIcon></th>
                <th pSortableColumn="email" class="font-medium" scope="col">Email <p-sortIcon field="email"></p-sortIcon></th>
                <th pSortableColumn="contractStatus" class="font-medium" scope="col">Contract Status <p-sortIcon field="contractStatus"></p-sortIcon></th>
                <th pSortableColumn="appStatus" class="font-medium" scope="col">App Status <p-sortIcon field="appStatus"></p-sortIcon></th>
                <th pSortableColumn="patientId" class="font-medium" scope="col">Patient Id <p-sortIcon field="patientId"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-queue>
            <tr (click)="selectRow(queue)" class="cursor-pointer">
                <td>{{ queue.accountNumber }}</td>
                <td>{{ queue.contractLimit }}</td>
                <td>{{ queue.firstName }}</td>
                <td>{{ queue.lastName }}</td>
                <td>{{ queue.phone }}</td>
                <td>{{ queue.city }}</td>
                <td>{{ queue.state }}</td>
                <td>{{ queue.email }}</td>
                <td>{{ queue.contractStatus }}</td>
                <td>{{ queue.appStatus }}</td>
                <td>{{ queue.patientId }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">No queue to display.</td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>