<div class="px-3 mb-2">
  <div class="px-3">
    <p class="text-xl m-2">Customer Attachments</p>
    <p-table [value]="docs" styleClass="p-datatable-lg mr-4" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Date</th>
          <th scope="col">CS Rep</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-doc>
        <tr>
          <td>
            <span class="mr-2">
              <i class="pi pi-file" aria-hidden="true"></i>
            </span>
            <span>
              <a [href]="doc.attachmentUrl" target="_blank">{{doc.messageSubject}}</a>
            </span>
          </td>
          <td>{{ doc.sendReceivedDate }}</td>
          <td>
            <span class="me-1">{{doc.senderFirstName}}</span>
            <span class="me-1">{{doc.senderLastName}}</span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">No documents to display.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="px-4 pb-3" *ngIf="hasFeature('CSR_CUSTOMER_COMMUNICATIONS_ATTACH_DOCUMENT')">
  <covered-button class="p-2 w-2" label="Attach" (buttonClick)="showAttach = true;"></covered-button>
</div>

<covered-dialog [isVisible]="showAttach" [isModal]="true" [isClosable]="false" 
  [showHeader]="true" header="Upload Customer Documents" headerClass="text-2xl" [style]="{'width': '25vw'}">
  <app-upload-attachment body (backClicked)="showAttach = false;"
    (uploadError)="showAttach = false; uploadError($event);"
    (fileUploaded)="showAttach = false; fileUploaded($event);">
  </app-upload-attachment>
</covered-dialog>

<covered-toast [key]="toastKey" [position]="toastPos"></covered-toast>