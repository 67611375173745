<div class="cds-card p-3">
    <p class="text-2xl ml-3 pt-3">Note List</p>
    <covered-progress-bar *ngIf="loading" [style]="{'height': '0.5rem'}"></covered-progress-bar>
    <div class="w-full pt-4">
        <p-table #dt [value]="notes" styleClass="p-datatable-lg mr-4 mb-4"
        [globalFilterFields]="displayedColumns" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" 
        [showCurrentPageReport]="true" currentPageReportTemplate="{totalRecords} total records" 
        [(first)]="first" responsiveLayout="scroll" [rows]="15" [rowHover]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="createDate" class="font-medium" scope="col">Create Date <p-sortIcon field="createDate"></p-sortIcon></th>
                <th pSortableColumn="loanNumber" class="font-medium" scope="col">App Number <p-sortIcon field="loanNumber"></p-sortIcon></th>
                <th pSortableColumn="customerFullName" class="font-medium" scope="col">Customer <p-sortIcon field="customerFullName"></p-sortIcon></th>
                <th pSortableColumn="noteCategoryDescription" class="font-medium" scope="col">Comment Code <p-sortIcon field="noteCategoryDescription"></p-sortIcon></th>
                <th pSortableColumn="note" class="font-medium" scope="col">Note <p-sortIcon field="note"></p-sortIcon></th>
                <th pSortableColumn="userFullName" class="font-medium" scope="col">User <p-sortIcon field="userFullName"></p-sortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-note>
            <tr>
                <td>{{ note.createDate }}</td>
                <td>{{ note.loanNumber }}</td>
                <td>{{ note.customerFullName }}</td>
                <td>{{ note.noteCategoryDescription }}</td>
                <td>{{ note.note }}</td>
                <td>{{ note.userFullName }}</td>
            </tr>
        </ng-template>
        <!-- <ng-template pTemplate="paginatorright" let-state>
            {{state.first}}-{{(state.first + state.rows)}} of {{state.totalRecords}}
        </ng-template> -->
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [colSpan]="6">No notes found.</td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>