<app-dashboard-header></app-dashboard-header>
<p class="text-2xl font-bold m-2">Customer Pre Application</p>
<div class="flex">
    <div class="col-8">
        <div class="cds-card">
            <div *ngIf="loading" class="col-12">
                <p class="text-3xl">Loading Data...</p>
                <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
            </div>
            <div *ngIf="!loading" class="text-base">
                <div class="flex py-4">
                    <div class="px-4">
                        <p class="text-4xl font-bold">Begin application for financing</p>
                    </div>
                    <div class="ml-auto pr-4">
                        <img src="/assets/images/logo.png" alt="logo" />
                    </div>
                </div>
                <p class="pl-4">Fill out the information below. Some fields are required before the application can
                    begin.</p>
                <p class="pl-4"><strong>Note:</strong> Required fields are marked with a *
                </p>
                <div class="flex pl-4 pb-2">
                    <div *ngFor="let type of patientTypes" class="mr-4">
                        <p-radioButton [inputId]="type.id" name="rbPatType" class="mr-2" [value]="type.id" [(ngModel)]="patientType" [disabled]="true"></p-radioButton>
                        <label [for]="type.id">{{ type.desc }}</label>
                    </div>
                </div>
                <div class="grid p-4">
                    <div class="col-6">
                        <covered-input id="firstName" placeholder="First Name" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.firstName" [required]="true" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="lastName" placeholder="Last Name" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.lastName" [required]="true" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="phoneNumber" placeholder="Valid Cell Phone Number" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.phoneNumber" [required]="true" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="emailAddress" placeholder="Valid Email Address" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.emailAddress" [required]="true" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="dob" placeholder="Date of birth" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            value="{{ details.dOB | date:'MM/dd/yyyy' }}" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="ssn" placeholder="Social Security Number" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.sSN" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="address" placeholder="Street Address" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.address" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="address2" placeholder="STE/APT #" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.address2" [disabled]="true"></covered-input>
                    </div>


                    <div class="col-4">
                        <covered-input id="city" placeholder="City" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.city" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-4">
                        <covered-input id="state" placeholder="State" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.state" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-4">
                        <covered-input id="zipCode" placeholder="Zipcode" labelStyleClass="text-900" inputStyleClass="opacity-100" 
                            [value]="details.zipCode" [disabled]="true"></covered-input>
                    </div>
                </div>
                <p class="text-3xl font-bold m-4">Office use only</p>
                <div class="grid p-4">
                    <div class="col-6">
                        <covered-input id="serviceDate" value="{{details.serviceDate | date:'MM/dd/yyyy'}}" placeholder="Service Date" 
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="doctorName" [value]="details.doctorName" placeholder="Doctor" 
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="serviceProcedure" placeholder="Procedure(s)" [required]="true"
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="loanAmt" [value]="details.loanAmt" placeholder="Amount To Be Financed" [required]="true" 
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="patientID" [value]="details.patientID" placeholder="Assign Unique Patient ID" 
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-6">
                        <covered-input id="assignTo" [value]="details.assignTo" placeholder="Assign to" 
                            labelStyleClass="text-900" inputStyleClass="opacity-100" [disabled]="true"></covered-input>
                    </div>
                    <div class="col-4">
                        <p-radioButton inputId="appLinkMethodLink" name="rbAppLink" class="mr-2" value="link" [(ngModel)]="details.patAppLinkMethod" 
                            [disabled]="true"></p-radioButton>
                        <label for="appLinkMethodLink">Send application link to</label>
                        <div *ngIf="details.patAppLinkMethod === 'link'" class="pt-4 pl-5">
                            <p><strong>Note:</strong> One or more must be selected</p>
                            <div class="flex col-8 justify-content-between">
                                <div>
                                <input [checked]="details.sendEmail === 'True'" disabled
                                    type="checkbox" id="linkCheckbox1" value="True">
                                    <label class="ml-2" for="linkCheckbox1">Email</label>
                                </div>
                                <div>
                                    <input [checked]="details.sendSMS === 'True'" disabled
                                     type="checkbox" id="linkCheckbox2" value="True">
                                    <label class="ml-2" for="linkCheckbox2">Cell Phone</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-offset-2 col-6">
                        <p-radioButton inputId="rbCont" name="appLinkMethodDevice" class="mr-2" value="device"
                            [(ngModel)]="details.patAppLinkMethod" [disabled]="true"></p-radioButton>
                        <label for="rbCont">Continue on device</label>
                    </div>
                </div>
                <p class="text-3xl font-bold m-4">Language Preference</p>
                <div class="flex col-2 ml-4 pb-6 justify-content-between">
                    <div>
                        <p-radioButton inputId="langEn" name="languageRadio" class="mr-2" value="EN"
                            [(ngModel)]="details.patLanguage" [disabled]="true"></p-radioButton>
                        <label for="langEn">English</label>
                    </div>
                    <div>
                        <p-radioButton inputId="langEs" name="languageRadio" class="mr-2" value="ES"
                            [(ngModel)]="details.patLanguage" [disabled]="true"></p-radioButton>
                        <label for="langEs">Spanish</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-4">
        <app-customer-notes></app-customer-notes>
    </div>
</div>