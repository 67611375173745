import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from './material-module';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SideNavComponent } from './components/layout/side-nav/side-nav.component';
import { ProfileMenuComponent } from './components/layout/profile-menu/profile-menu.component';
import { NavHeaderComponent } from './components/layout/nav-header/nav-header.component';
import { CcInputComponent } from './components/form-controls/cc-input/cc-input.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { PreApplicationPageComponent } from './pages/pre-application-page/pre-application-page.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { DecisionPageComponent } from './pages/decision-page/decision-page.component';
import { AccountServicingPageComponent } from './pages/account-servicing-page/account-servicing-page.component';
import { CommunicationsPageComponent } from './pages/communications-page/communications-page.component';
import { AgentToolsPageComponent } from './pages/agent-tools-page/agent-tools-page.component';
import { SystemsPageComponent } from './pages/systems-page/systems-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { CustomerInfoEditComponent } from './components/customer/customer-info-edit/customer-info-edit.component';
import { CutomerNotesComponent } from './components/cutomer-notes/cutomer-notes.component';
import { AddCustomerNotificationComponent } from './components/add-customer-notification/add-customer-notification.component';
import { CustomerContactComponent } from './components/customer-contact/customer-contact.component';
import { CcSelectComponent } from './components/form-controls/cc-select/cc-select.component';
import { CcTextareaComponent } from './components/form-controls/cc-textarea/cc-textarea.component';
import { PaymentMethodInfoComponent } from './components/payment-method-info/payment-method-info.component';
import { CustomGridComponent } from './components/custom-grid/custom-grid.component';
import { AdvancedSearchComponent } from './pages/advanced-search/advanced-search.component';
import { MatTableFilterModule } from 'mat-table-filter';
import { QueueCollectionsPageComponent } from './pages/queue-collections-page/queue-collections-page.component';
import { LeadsPageComponent } from './pages/leads-page/leads-page.component';
import { VerificationQueuePageComponent } from './pages/verification-queue-page/verification-queue-page.component'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsQueuePageComponent } from './pages/communications-queue-page/communications-queue-page.component';
import { FullScreenLoadingComponent } from './components/full-screen-loading/full-screen-loading.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { MyProfilePageComponent } from './pages/my-profile-page/my-profile-page.component';
import { ConfirmChangeModalComponent } from './components/confirm-change-modal/confirm-change-modal.component';
import { NoteListPageComponent } from './pages/note-list-page/note-list-page.component';
import { FormatMoneyPipe } from './pipes/format-money.pipe';
import { YesNoPopupComponent } from './components/shared/yes-no-popup/yes-no-popup.component';
import { TenDayPayoffComponent } from './components/account-servicing/ten-day-payoff/ten-day-payoff.component';
import { SchedulePaymentFormComponent } from './components/account-servicing/schedule-payment-form/schedule-payment-form.component';
import { CcRadioComponent } from './components/form-controls/cc-radio/cc-radio.component'; 
import { UpcomingPaymentsComponent } from './components/account-servicing/upcoming-payments/upcoming-payments.component'; 
import { CourtesyAdjustmentPageComponent } from './pages/courtesy-adjustment-page/courtesy-adjustment-page.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { ManualPaymentsPageComponent } from './pages/manual-payments-page/manual-payments-page.component'; 
import { ReRunRulesComponent } from './components/decision/re-run-rules/re-run-rules.component';
import { CommMailboxComponent } from './components/communications/comm-mailbox/comm-mailbox.component';
import { CommTabComponent } from './components/communications/comm-tab/comm-tab.component';
import { CommMailboxItemComponent } from './components/communications/comm-mailbox-item/comm-mailbox-item.component';
import { ConfirmDeleteModalComponent } from './components/shared/confirm-delete-modal/confirm-delete-modal.component';
import { AddressCorrectionComponent } from './components/customer/address-correction/address-correction.component';
import { ConfirmChangesComponent } from './components/customer/confirm-changes/confirm-changes.component';
import { EditEmailModalComponent } from './components/customer/edit-email-modal/edit-email-modal.component';
import { ConfirmEmailCodeModalComponent } from './components/customer/confirm-email-code-modal/confirm-email-code-modal.component';
import { AddPaymentMethodModalComponent } from './components/customer/add-payment-method-modal/add-payment-method-modal.component';
import { ContractDocumentsComponent } from './components/communications/contract-documents/contract-documents.component';
import { CustomerAttachmentsComponent } from './components/communications/customer-attachments/customer-attachments.component';
import { UploadAttachmentComponent } from './components/communications/upload-attachment/upload-attachment.component';
import { UploadDirective } from './directives/upload.directive'; 

import { CoveredLibModule } from '@covered/covered-ui';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog'
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabMenuModule } from 'primeng/tabmenu';
import { BadgeModule } from 'primeng/badge';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';

import { SuccessComponent } from './components/success/success.component';
import { CommSmsComponent } from './components/communications/comm-sms/comm-sms.component';
import { CommEmailComponent } from './components/communications/comm-email/comm-email.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MsalApplicationModule } from './msal-application.module';
import { ProviderMenuComponent } from './components/provider-menu/provider-menu.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SideNavComponent,
    ProfileMenuComponent,
    NavHeaderComponent,
    CcInputComponent,
    DashboardHeaderComponent,
    PreApplicationPageComponent,
    ApplicationPageComponent,
    DecisionPageComponent,
    AccountServicingPageComponent,
    CommunicationsPageComponent,
    AgentToolsPageComponent,
    SystemsPageComponent,
    DashboardPageComponent,
    CustomerInfoComponent,
    CustomerInfoEditComponent,
    CutomerNotesComponent,
    AddCustomerNotificationComponent,
    CustomerContactComponent,
    CcSelectComponent,
    CcTextareaComponent,
    PaymentMethodInfoComponent,
    CustomGridComponent,
    SystemsPageComponent,
    AdvancedSearchComponent,
    QueueCollectionsPageComponent,
    LeadsPageComponent,
    VerificationQueuePageComponent,
    CommunicationsQueuePageComponent,
    FullScreenLoadingComponent,
    SuccessModalComponent,
    MyProfilePageComponent,
    ConfirmChangeModalComponent,
    NoteListPageComponent,
    FormatMoneyPipe,
    YesNoPopupComponent,
    TenDayPayoffComponent,
    UpcomingPaymentsComponent,
    SchedulePaymentFormComponent,
    CcRadioComponent,
    CourtesyAdjustmentPageComponent,
    ReRunRulesComponent,
    ErrorModalComponent,
    ManualPaymentsPageComponent,
    CommMailboxComponent,
    CommTabComponent,
    CommMailboxItemComponent,
    ConfirmDeleteModalComponent,
    AddressCorrectionComponent,
    ConfirmChangesComponent,
    EditEmailModalComponent,
    ConfirmEmailCodeModalComponent,
    AddPaymentMethodModalComponent, 
    ContractDocumentsComponent,
    CustomerAttachmentsComponent,
    UploadAttachmentComponent,
    UploadDirective,
    SuccessComponent,
    CommSmsComponent,
    CommEmailComponent,
    LogoutComponent,
    ProviderMenuComponent,
    NotAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    MatTableFilterModule,
    AppRoutingModule,
    AppMaterialModule,
    CoveredLibModule,
    TableModule,
    ButtonModule,
    RadioButtonModule,
    InputTextModule,
    InputTextareaModule,
    BreadcrumbModule,
    OverlayPanelModule,
    DialogModule,
    TabMenuModule,
    BadgeModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MsalApplicationModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiInterceptor,
    //   multi: true
    // },
    // MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
