import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { MessageFromTemplate } from 'src/app/models/message-template';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-comm-email',
  templateUrl: './comm-email.component.html',
  styleUrls: ['./comm-email.component.scss']
})
export class CommEmailComponent implements OnInit, OnChanges {

  @Output() onBack = new EventEmitter();
  @Output() loadMsg = new EventEmitter<Message>();
  @Input() ids: ActivatedIds = new ActivatedIds;
  @Input() customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
  @Input() messageTemplateGuid: any = null;
  
  emailForm: FormGroup = {} as FormGroup;

  fromItems: MessageFromTemplate[] = [];
  disableTo: boolean = false;
  isTemplate:boolean = false;
  templateName: string = '';
  static defaultMessageTypeId: string = '2';

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      emailTo: [null, [Validators.required]],
      emailFrom: [null, [Validators.required]],
      emailSubject: [null, Validators.required],
      emailBody: [null, Validators.required]
    });

    if (this.customerInfo) {
      this.emailForm.patchValue({emailTo: `${this.customerInfo.emailAddress} (${this.customerInfo.firstName} ${this.customerInfo.lastName})`});
      this.disableTo = true;

    }

    let fromSub = this.apiService.get(`communications/message-category-readall/${this.ids.customerGuid}`)
    .subscribe({ 
      next: (res: any) => {
        if (res && res.length > 0) {
          this.fromItems = res;
          for(let i = 0; i < res.length; i++) {
            let item = this.fromItems[i];
            this.fromItems[i].ddLabel = `${item.messageCategoryName} (${item.profileName})`;
          }
        }        
      },
      error: (err: any) => { console.error(err); },
      complete: () => { fromSub.unsubscribe(); }
    });
    

    this.checkGetMessageTemplate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageTemplateGuid']) {
      // this.smsMsg = null;
      // this.checkGetMessageTemplate();
    }
  }

  checkGetMessageTemplate() { 

    if (this.messageTemplateGuid) {
      let custSub = this.customerService.getMessageTemplate(this.messageTemplateGuid)
      .subscribe({
        next: (res: any) => {
          // if (res && res.body && res.body.length) {
          //   this.smsMsg = res.body;
          // }
        },
        error: (err: any) => { console.error(err); },
        complete: () => { custSub.unsubscribe(); }
      })
    }
    else {
      // this.smsMsg = null;
    }
  }


  back() {
    this.onBack.emit();
  }

  sendEmail() {

  }

}
