<div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
</div>
<div *ngIf="!loading" class="cds-card p-3">
    <div *ngIf="info" class="flex flex-wrap flex-auto">
        <div class="col-6 text-2xl">{{ GridTitle }}</div>
        <div *ngIf="info.allowSearch" class="col-offset-3 col-2 flex-grow-1 align-content-end ">
            <span class="p-input-icon-left">
                <i class="pi pi-search" aria-hidden="true"></i>
                <input #txtSearch [(ngModel)]="searchFilter" type="text" class="w-15rem" pInputText placeholder="Search"
                    (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
            </span>
        </div>
        <div class="w-full pt-4">
            <p-table #dt [value]="data" styleClass="p-datatable-lg mr-4 mb-4"
            [globalFilterFields]="info.columnHeaders" [paginator]="AllowPaging" [rowsPerPageOptions]="[15, 30, 50, 100]" 
            [showCurrentPageReport]="false" [(first)]="first" responsiveLayout="scroll" [rows]="15" [rowHover]="true">
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="info.allowSort">
                    <th *ngFor="let col of info.columnNames; index as i;" pSortableColumn="{{info.columnHeaders[i]}}" class="font-medium" scope="col">{{info.columnHeaders[i]}} <p-sortIcon field="{{info.columnHeaders[i]}}"></p-sortIcon></th>                    
                </tr>
                <tr *ngIf="!info.allowSort">
                    <th *ngFor="let col of info.columnNames; index as i;" class="font-medium" scope="col">{{info.columnHeaders[i]}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [ngClass]="showPointer ? 'cursor-pointer' : ''" (click)="rowSelected(rowData)">
                   <td *ngFor="let col of info.columnNames; index as i;">
                       {{ rowData[info.columnHeaders[i]] }}
                   </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [colSpan]="info.columnNames.length">No Data found.</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
    </div>
</div>