import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SettingService {

  private http: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
   }

  private configSettings: any = null;

  get settings() {
    return this.configSettings;
  }

  public load(propName: string | any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      var requestUrl = `${environment.apiEndpoint}settings/app`;
      const http= new HttpClient(this.httpHandler);
      this.http.get<any>(requestUrl).subscribe(result => {
        this.configSettings = result;
        if (propName && this.configSettings[propName])
          resolve(this.configSettings[propName]);
        else {
          resolve(this.configSettings);
        }
      }, err => {
        reject(err);
      });
    });
  }

  private getAsync(propName: string): Promise<any> {
    return new Promise((resolve) => {
      if (this.configSettings && this.configSettings[propName])
        resolve(this.configSettings[propName]);
      else {
        resolve(this.load(propName));
      }
    });
  }

  get(key?: string): any {
    if (!key) {
      return this.configSettings;
    }
    return this.configSettings[key];
  }

  getValue(key: string): any {
    return this.configSettings[key];
  }

  glientId(): Promise<any> {
    return this.getAsync('AD_AppReg_ClientId');
  }

  tenantName(): Promise<any> {
    return this.getAsync('B2C_TenantName');
  }

  authorityDomain(): Promise<any> {
    return this.getAsync('B2C_AuthorityDomain');
  }

  adminAppUrl(): Promise<any> {
    return this.getAsync('URL_Admin');
  }

  csAppUrl(): Promise<any> {
    return this.getAsync('URL_CS');
  }

  bopAppUrl(): Promise<any> {
    return this.getAsync('URL_BOP');
  }

  signInPolicy(): Promise<any> {
    return this.getAsync('B2C_SignInPolicy');
  }

  signInPolicyAlly(): Promise<any> {
    return this.getAsync('B2C_SignInPolicy_Ally');
  }

  forgotPasswordPolicy(): Promise<any> {
    return this.getAsync('B2C_ForgotPasswordPolicy');
  }

  forgotPasswordPolicyAlly(): Promise<any> {
    return this.getAsync('B2C_ForgotPasswordPolicy_Ally');
  }

  scopeUris(): Promise<any> {
    return this.getAsync('App_ScopeURLs');
  }

  apiEndpoint() {
    return this.getAsync('API_Endpoint');
  }

}
