import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-re-run-rules',
  templateUrl: './re-run-rules.component.html',
  styleUrls: ['./re-run-rules.component.scss']
})
export class ReRunRulesComponent implements OnInit {

  iconCheck = faExclamationCircle;
  runAction = new FormControl('save');
  constructor(
    public dialogRef: MatDialogRef<ReRunRulesComponent>,
  ) {}

  onClick(msg: string): void {
    var res = msg == 'yes' ? this.runAction.value : null;
    this.dialogRef.close(res);
  }

  ngOnInit(): void {
  }

}
