<app-dashboard-header></app-dashboard-header>
<h4>System Tools</h4>
<div class="row">
    <div class="col-lg-8 col-md-12">
        <app-custom-grid [DataSourceName]="loginTrackingDataSource" [GridTitle]="'Login Tracking'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="fieldChangeTrackingDataSource" [GridTitle]="'Field Change Tracking'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="achStatusHistoryDataSource" [GridTitle]="'ACH Status History'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="payStatusHistoryDataSource" [GridTitle]="'Pay Status History'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="cardTransactionsTrackingDataSource" [GridTitle]="'Debit/Credit Card Tracking'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="recentActivityDataSource" [GridTitle]="'Customer Recent Activity'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="statusChangeTrackingDataSource" [GridTitle]="'Customer Status Changes'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="legalStatusChangeTrackingDataSource" [GridTitle]="'Customer Legal Status Changes'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="pageTrackingDataSource" [GridTitle]="'Page Tracking'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="sessionTrackingDataSource" [GridTitle]="'Session Tracking'" [AllowPaging]="true"></app-custom-grid>
        <app-custom-grid [DataSourceName]="nlsApiLogDataSource" [GridTitle]="'NLS API Logs'" [AllowPaging]="false"></app-custom-grid>
        <app-custom-grid [DataSourceName]="loanAppDataSource" [GridTitle]="'Contract App Save History'" [AllowPaging]="false"></app-custom-grid>
    </div>
    <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-lg-4 col-md-12">
        <app-customer-notes></app-customer-notes>
    </div>
</div>
