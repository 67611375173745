import { Component, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { CustomerInfoLoanModel } from 'src/app/models/customer-info-loan-model';
import { MessageTemplate } from 'src/app/models/message-template';
import { MailboxCount } from 'src/app/pages/communications-page/communications-page.component';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-comm-tab',
  templateUrl: './comm-tab.component.html',
  styleUrls: ['./comm-tab.component.scss']
})
export class CommTabComponent implements OnInit {
  ids: ActivatedIds = new ActivatedIds;
  customerInfo: CustomerInfoLoanModel = {} as CustomerInfoLoanModel;
  
  mailbox: 'inbox' | 'draft' = 'inbox';
  mailboxLabel: string = 'Mailbox';
  inboxUnread = 0;
  draftsCount = 0;
  templates: MessageTemplate[] = [];
  showTemplateChoices: boolean = false;

  showMailboxes: boolean = true;
  showTemplates: boolean = false;
  showSMS: boolean = false;
  showEmail: boolean = false;
  smsMsgTempGuid: any = null;

  toastKey: string = 'payToast';
  toastPos: string = 'center';

  constructor(
    private customerService: CustomerService,
    private mainService: MainService,
    private apiService: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.mainService.activatedIds$.subscribe(id => {
      if (id && this.ids.targetGuid !== id.targetGuid) {
        this.ids = id;
        this.getLoadData();
      }
    });
  }

  getLoadData() {
    this.apiService.get(`csr/loan-data/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe((res: any) => {
        this.customerInfo = res.loanData;
      },
        (err: any) => {
          console.error(err);
        }
      );
  }

  setCount(count: MailboxCount) {
    this.inboxUnread = count.inbox;
    this.draftsCount = count.draft;
  }

  showMailbox(box: 'inbox' | 'draft') {
    this.showTemplates = false;
    this.showEmail = false;
    this.showSMS = false;
    this.showMailboxes = true;
    this.mailbox = box;
    this.mailboxLabel = box === 'inbox' ? 'Mailbox' : 'Drafts';
  } 

  getTemplates() {
    let commSub = this.customerService.getAllCommunications()
    .subscribe({
      next: (res: MessageTemplate[]) => { 
        this.templates = res;
      },
      error: (err: any) => {
        console.error('comm-tab component ngOnInit customerService.getAllCommunications error: ', err);
       },
      complete: () => { commSub.unsubscribe(); }
    })
  }

  getBadgeStyle(template: any): string {
    let badgeStyle: string = 'bg-orange-500';
    
    if (template.messageTypeID === '4')
      badgeStyle = 'surface-500';

    return badgeStyle;
  }

  loadTemplates() {
    this.showTemplates = true;
    this.showEmail = false;
    this.showSMS = false;
    this.showMailboxes = false;
    this.getTemplates();
  }
  
  clearSMS() {
    this.smsMsgTempGuid = null;
  }

  loadSMS() { 
    this.showTemplates = false;
    this.showEmail = false;
    this.showSMS = true;
    this.showMailboxes = false;
  }

  loadEmail() { 
    this.showTemplates = false;
    this.showEmail = true;
    this.showSMS = false;
    this.showMailboxes = false;

  }
  
  loadTemplate(template: MessageTemplate) {
    if (template.messageTypeID === '4') {   //  Load SMS
      this.smsMsgTempGuid = template.messageTemplateGUID;
      this.loadSMS();
    }
    else {                                  //  Load Email
      this.loadEmail();
    }
  }

  addMessage(message: Message) {
    message.key = this.toastKey;
    this.messageService.add(message);
  }
}