export const LEGAL_STATUS_OPTIONS = [
    {id:'1', desc:'None'},
    {id:'2', desc:'Compliance'},
    {id:'3', desc:'Legal'},
    {id:'6', desc:'Deceased'},
    {id:'7', desc:'Bankruptcy'},
    {id:'8', desc:'Debt Consolidation'},
    {id:'9', desc:'Fraud'},
    {id:'12', desc:'Pending Cancellation'},
    {id:'13', desc:'Military SCRA'},
    {id:'14', desc:'Cease and Desist'},
    {id:'15', desc:'Charged Off'},
    {id:'16', desc:'Treatment Escalation'},
]

