<div class="pt-3">
    <div class="flex pb-3 border-bottom-1 border-black-alpha-10">
        <div class="col-11 flex flex-wrap">
            <span class="text-2xl font-medium col-12">Compose</span>
            <div class="text-base col-12">New Email
                <span *ngIf="isTemplate">(<span class="font-medium">{{ templateName }}</span> Template)</span>
            </div>
        </div>
        <div class="col-1 align-self-center">
            <covered-button id="btnBack" class="outlined" (buttonClick)="back()">
                <i class="pi pi-arrow-left" aria-hidden="true"></i>
            </covered-button>
        </div>
    </div>
    <form [formGroup]="emailForm" (ngSubmit)="sendEmail()">
        <div class="pt-3">
            <covered-form-text id="txtTo" placeholder="To:" [disabled]="disableTo" formControlName="emailTo">
            </covered-form-text>
        </div>
        <div class="pt-3">
            <covered-dropdown id="ddFrom" placeholder="From:" [items]="fromItems" optionLabel="ddLabel"
                formControlName="emailFrom" [displayFirst]="false" [showClear]="true">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="emailForm.controls['emailFrom'].touched && emailForm.controls['emailFrom'].invalid">
                    <p>From address is required</p>
                </div>
            </covered-dropdown>
        </div>
        <div class="pt-3">
            <covered-form-text id="txtSub" placeholder="Subject:" formControlName="emailSubject">
                <div class="w-12 my-1 p-error text-xs"
                    *ngIf="emailForm.controls['emailSubject'].touched && emailForm.controls['emailSubject'].invalid">
                    <p>Subject is required</p>
                </div>
            </covered-form-text>
        </div>
        <div class="pt-5">
            Email body:
            <p-editor formControlName="emailBody" [style]="{'height': '20rem'}"></p-editor>
            <!-- <span class="p-float-label">
            <textarea pInputTextarea id="sms-msg" rows="5" cols="30" style="width: 100%;" [(ngModel)]="smsMsg"></textarea>
            <label for="sms-msg">SMS Text:</label>
        </span> -->
        </div>
        <div class="grid pt-3">
            <div class="col-2">
                <!-- <covered-button id="btnSend" label="Send" icon="pi pi-mobile" class="w-10" 
                [disabled]="!smsMsg || smsMsg.length < 1" (buttonClick)="sendSMS()">
            </covered-button> -->
            </div>
            <div class="col-2">
                <!-- <covered-button id="btnCancel" label="Discard" class="w-7 outlined" (buttonClick)="discard()"></covered-button> -->
            </div>
        </div>
    </form>
</div>