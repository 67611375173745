<app-dashboard-header></app-dashboard-header>
<div class="col-5 align-self-center">
    <div class="row">
        <div class="page-breadcrumb">
            <h4 class="page-title">Courtesy Adjustment</h4>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/agent-tools/{{customerGuid}}/{{campaignGuid}}/{{targetGuid}}">Agent Tools</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Courtesy Adjustment
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row p-3">
        <div class="col-4">            
            <div class="cds-card">
                <div class="card-body">
                    <h4 class="card-title">
                        Account Adjustments
                    </h4>
                    <div class="form-group mb-2">
                        <form [formGroup]="accountAdjustments">
                            <div class="p-grid p-fluid">
                            <div class="p-field p-col-12 p-md-1">
                                <covered-input-number formControlName="principal" id="principal" placeholder="Principal Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                                    <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="!accountAdjustments.controls['principal'].pristine && accountAdjustments.controls['principal'].errors">   
                                        <p *ngIf="accountAdjustments.controls['principal'].errors?.required">Required</p>
                                        <p *ngIf="accountAdjustments.controls['principal'].errors?.min">Invalid input.</p>   
                                        <p *ngIf="accountAdjustments.controls['principal'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.principalBalance}})</p>  
                                    </div>
                                </covered-input-number>
                            </div>
                            <div class="p-field p-col-12 p-md-1">
                                <covered-input-number formControlName="interest" id="interest" placeholder="Interest Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                                    <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="!accountAdjustments.controls['interest'].pristine && accountAdjustments.controls['interest'].errors">
                                        <p *ngIf="accountAdjustments.controls['interest'].errors?.required">Required</p>
                                        <p *ngIf="accountAdjustments.controls['interest'].errors?.min">Invalid input.</p>   
                                        <p *ngIf="accountAdjustments.controls['interest'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.interestBalance}})</p>
                                    </div>
                                </covered-input-number>
                            </div>
                            <div class="p-field p-col-12 p-md-1" >
                                  <covered-input-number formControlName="lateFee" id="lateFee" placeholder="Late Fee Adjustment" [required]="true" (blurEvent)="updateTotalAdjustment($event);">
                                    <div class="w-12 my-1 p-error text-xs"
                                            *ngIf="!accountAdjustments.controls['lateFee'].pristine && accountAdjustments.controls['lateFee'].errors">
                                        <p *ngIf="accountAdjustments.controls['lateFee'].errors?.required">Required</p>
                                        <p *ngIf="accountAdjustments.controls['lateFee'].errors?.min">Invalid input.</p>   
                                        <p *ngIf="accountAdjustments.controls['lateFee'].errors?.max">Invalid Input ( Max = ${{this.accountSummary.feesBalance}})</p>
                                    </div>
                                </covered-input-number>
                            </div>
                        </div>
                        </form>
                        <div class="row">
                            <div class="col-6 text-muted">Total Fee(s) Adjustment</div>
                            <div class="col-6 text-end"><strong>{{totalAdjustment | currency}}</strong></div>
                        </div>
                        <!-- <covered-button type="button" (click)="confirmAccountAdjustment()">CONFIRM ACCOUNT ADJUSTMENT</covered-button> -->
                        <covered-button buttonType="submit" (click)="confirmAccountAdjustment()" [disabled]="accountAdjustments.invalid">Confirm Account Adjustment</covered-button>

                    </div>
                </div>            
            </div>
        </div>
        <div class="col-4">            
            <div class="cds-card">
                <div class="card-body">
                    <h4 class="card-title pb-3">Credit Card Adjustments</h4>
                    <div class="form-group mb-2">
                        <div class="form-group">
                            <covered-dropdown id="selectedPayment" optionLabel="description" placeholder="Choose Refund Card Payments"
                                [items]="cardPayments" [displayFirst]="false" [showClear]="false" [(ngModel)]="selectedPayment" (optionSelected)="setPaymentRefund($event)">
                            </covered-dropdown>

                        </div>
                        <div class="form-group py-3" *ngIf="selectedPayment"> 
                            <label class="form-label clabel pl-3">Amount to refund (Partial adjustments will not be reflected in NLS)</label>
                            <covered-input-number class="pl-3" id="paymentRefund" [(ngModel)]="manualRefundAmount" (blurEvent)="validateCreditCard()">
                                <div *ngIf="(selectedPayment && (manualRefundAmount > selectedPayment.amount))" class="w-12 my-1 p-error text-xs">
                                    <p>Invalid Input</p>
                                </div>
                            </covered-input-number>
                        </div>
                        <covered-button type="button" (click)="confirmCardAdjustment()" [disabled]="disableCreditCardPayment">CONFIRM ADJUSTMENT</covered-button>
                    </div>
                </div>
            </div>            
            <div class="cds-card">
                <div class="card-body">
                    <h4 class="card-title">Bank Account Adjustments</h4>
                    <div class="form-group mb-2">                        
                        <div class="form-group">
                            <label class="form-label clabel">Enter Amount</label>
                            <covered-input-number class="pl-3" id="manualBankAdjustment" [(ngModel)]="manualBankAdjustment" (blurEvent)="manualBankAdjBlur=true; validateBankAmount()">
                                <div *ngIf="(manualBankAdjBlur && manualBankAdjustment == 0)" class="w-12 my-1 p-error text-xs">
                                    <p>Invalid Input</p>
                                </div>
                            </covered-input-number>
                        </div>
                        <div class="form-group">
                            <label class="form-label clabel">Payment Method</label>
                            <covered-dropdown id="selectedBankPayment" optionLabel="loanPaymentMethodDescription" 
                                [items]="info.paymentMethods" [displayFirst]="false" [showClear]="false" [(ngModel)]="selectedMethod" (optionSelected)="validateBankAmount()">
                            </covered-dropdown>
                        </div>
                        <covered-button type="button" (click)="confirmBankAdjustment()" [disabled]="disableBankPayment">CONFIRM ADJUSTMENT</covered-button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-4">
            <app-customer-notes></app-customer-notes>
        </div>
    </div>
</div>
<covered-toast [key]="toastKeyAcct" [position]="toastPos"></covered-toast>
<covered-dialog [isVisible]="showAcctAdjModal" header="Account Adjustment" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showAcctAdjModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of ${{ totalAdjustment }}. You should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>  
    <div footer class="justify-content-around">
        <covered-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3" (buttonClick)="showAcctAdjModal=false;"></covered-button>
        <covered-button id="btnDialog1Ok" label="Confirm" class="w-3" (buttonClick)="showAcctAdjModal=false;postAccountAdjustment();"></covered-button>
    </div>
</covered-dialog>

<covered-toast [key]="toastKeyCredit" [position]="toastPos"></covered-toast>
<covered-dialog [isVisible]="showCreditCardModal" header="Card Adjustment" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showCreditCardModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of ${{ manualRefundAmount }}. You should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>  
    <div footer class="justify-content-around">
        <covered-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3" (buttonClick)="showCreditCardModal=false;"></covered-button>
        <covered-button id="btnDialog1Ok" label="Confirm" class="w-3" (buttonClick)="showCreditCardModal=false;postCardAdjustment();"></covered-button>
    </div>
</covered-dialog>

<covered-toast [key]="toastKeyBank" [position]="toastPos"></covered-toast>
<covered-dialog [isVisible]="showBankAcctModal" header="Bank Account Adjustment" headerClass="text-2xl" [showHeader]="true" [isModal]="true" 
    [isClosable]="true" (HideClick)="showBankAcctModal=false;" [style]="{'width': '25vw'}">
    <div body>
        <div class="row">
            <div class="text-center">
                <span>Mr/Ms. {{ accountSummary.lastName }} we are processing the amount of ${{ manualBankAdjustment }}. You should see this credit reflected in your account the next day.</span>
            </div>
        </div>
    </div>  
    <div footer class="justify-content-around">
        <covered-button id="btnDialog1Cancel" label="Back" class="p-button-outlined w-3" (buttonClick)="showBankAcctModal=false;"></covered-button>
        <covered-button id="btnDialog1Ok" label="Confirm" class="w-3" (buttonClick)="showBankAcctModal=false;postBankAdjustment();"></covered-button>
    </div>
</covered-dialog>