<p class="text-2xl font-bold m-2">Communications Queue</p>
<div class="cds-card flex p-4">
    <div class="col-1 border-right-1 border-500">
        <button class="p-button w-12 bg-orange-500 border-orange-500 text-right" (click)="opCompose.toggle($event)">Compose</button>
        <!-- <covered-button id="btnComponse" label="Compose" class="w-12 bg-orange-500 border-orange-500" (buttonClick)="opCompose.toggle(null);"></covered-button> -->
 
<p-overlayPanel #opCompose [showCloseIcon]="false">
    <ng-template pTemplate>
        <div class="h-auto">
            <div class="border-bottom-1">Free Form SMS</div>
            <div class="">Free Form Email</div>
        </div>
    </ng-template>
</p-overlayPanel>
    </div>
    <div class="col-11">main view</div>
</div>

