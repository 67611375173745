import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { PatientAppDetails } from 'src/app/models/patient-app-details';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { CustomGridComponent } from 'src/app/components/custom-grid/custom-grid.component';
import { CutomerNotesComponent } from 'src/app/components/cutomer-notes/cutomer-notes.component';
import { GuidService } from 'src/app/services/guid.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-systems-page',
  templateUrl: './systems-page.component.html',
  styleUrls: ['./systems-page.component.scss']
})
export class SystemsPageComponent implements OnInit {

  details: PatientAppDetails = new PatientAppDetails;
  ids: ActivatedIds = {};
  loading = false;

  loginTrackingDataSource: string = "dsCSRCustomerSystemToolsLoginTracking";
  plaidTrackingDataSource: string = "dsCSRCustomerSystemToolsPLAIDTracking";
  plaidChangeTrackingDataSource: string = "dsCSRCustomerSystemToolsPlaidChangeTracking";
  fieldChangeTrackingDataSource: string = "dsCSRCustomerSystemToolsFieldChangeTracking";
  achStatusHistoryDataSource: string = "dsCSRCustomerSystemToolsACHStatusHistory";
  payStatusHistoryDataSource: string = "dsCSRCustomerSystemToolsPayStatusHistory";
  cardTransactionsTrackingDataSource: string = "dsCSRCustomerSystemToolsCardTransactionsTracking";
  recentActivityDataSource: string = "dsCSRCustomerSystemToolsRecentActivity";
  statusChangeTrackingDataSource: string = "dsCSRCustomerSystemToolsStatusChanges";
  legalStatusChangeTrackingDataSource: string = "dsCSRCustomerSystemToolsLegalStatusChanges";
  pageTrackingDataSource: string = "dsCSRCustomerSystemToolsPageTracking";
  sessionTrackingDataSource: string = "dsCSRCustomerSystemToolsSessionTracking";
  nlsApiLogDataSource: string = "dsCSRCustomerSystemToolsNLSAPILogs";
  loanAppDataSource: string = "dsCSRCustomerSystemToolsLoanAppSaveHistory";
  inviteCodeTrackingDataSource: string = "dsCSRCustomerSystemToolsInviteCodeTracking";


  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private guidService: GuidService,
    private userService: UserService,
    private main: MainService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        let customerGuid = cGuid;
        this.customerService.initCustomer(customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }

        this.main.activatedIds$.subscribe((ids) => {
          if (ids && ids.targetGuid) {
            if (!this.ids || this.ids.targetGuid !== ids.targetGuid) {
              this.ids = ids;
              this.getPatientDetails();
            }
          }
        });
      }
    });
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  getPatientDetails() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid) {
      this.loading = true;
      this.apiService.get(`app/patient-app-details/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
        .subscribe((res: any) => {
          this.loading = false;
          this.details = res;
        },
          (err: any) => {
            console.error(err);
            this.loading = false;
          }
        );
    }
  }
}
