import { Component, OnInit } from '@angular/core';
import { DashboardHeaderComponent } from 'src/app/components/dashboard-header/dashboard-header.component';
import { LookupService } from 'src/app/services/lookup.service';
import { LookupModel } from 'src/app/models/lookup-model';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { AdvancedSearchParams } from 'src/app/models/advanced-search-params';
import { CcInputComponent } from 'src/app/components/form-controls/cc-input/cc-input.component';
import { CcSelectComponent } from 'src/app/components/form-controls/cc-select/cc-select.component';
import { EmailValidator, Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phoneRegex, ssnRegex } from 'src/app/services/util.service';
import { GuidService } from 'src/app/services/guid.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

  constructor(private lookupService: LookupService, 
    private apiService: ApiService,
    private main: MainService,
    private activatedRoute: ActivatedRoute, 
    private guidService: GuidService,
    private userService: UserService,
    private route: Router,
    private customerService: CustomerService,
    private builder: FormBuilder) { }

  states: LookupModel[] = [];
  ids: ActivatedIds = {};
  customerGuid: string | undefined = "";
  legalStatuses: LookupModel[] = [];
  appStatuses: LookupModel[] = [];
  params: AdvancedSearchParams = {};
  search: FormGroup = new FormGroup({});

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe(async paramMap => {
      let cGuid = paramMap.get('customerGuid')?.toString() ?? '';
      if (this.guidService.isValidGuid(cGuid)) {
        this.customerGuid = cGuid;
        this.customerService.initCustomer(this.customerGuid);
        await this.userService.subscribeAccess();
        let data = this.activatedRoute.snapshot.data;
        if (data && data['pageId']) {
          this.userService.checkPageAccess(data['pageId']);
        }
        this.initPage();
      }
    });
  }

  initPage() {
    this.main.activatedIds$.subscribe((ids) => {
      if (ids && ids.targetGuid){
        if (!this.ids || this.ids.targetGuid !== ids.targetGuid){
          this.ids = ids;
        }
      }
    });
    this.initForm();
    this.getLookup();
    this.states = this.lookupService.getStates();
  }

  initForm(){
    this.search = this.builder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      account: [''],
      appStatusID: [''],
      legalStatusID: [''],
      state: [''],
      patientID: [''],
      socialSecurityNumber: [''],
    });
  }

  getLookup(): void {
    this.apiService.get(`Csr/lookup-data/${this.customerGuid}`).subscribe((res) => {
      this.legalStatuses = res.legalStatuses;
      this.appStatuses = res.appStatuses;
    }, (err) => {
      console.error(err);
    });
  }

  submitSearch(){
    this.params = this.search.value;
    this.main.queueSimpleSearched(this.search.value);
    this.route.navigate([`/home/${this.customerGuid}`])
  }
}
