export class TargetInfoModel {
    accountPhoneMe = "False";
    acctrefno = "";
    arbitration = "False";
    autoDial = "False";
    cSRAggentPass = "True";
    campaignDomain = "dangt.apply.coveredcare.io";
    campaignDomainProtocol = "";
    campaignFolder = "";
    campaignID = "1029";
    campaignName = "";
    cancelShow = "False";
    daysPastDue = "0";
    docsIncompleteShow = "False";
    emailAddress = "";
    firstName = "";
    fullName = "";
    fundingType = "";
    inVerificationMode = "True";
    invitationCode = "";
    isConcession = "";
    lastName = "test";
    legalStatus = "None";
    legalStatusID = "1";
    loanAppDate = "";
    loanAppStatus = "";
    loanNumber = "";
    loanOrgDate = "";
    loanStatus = "Active";
    loanStatusID = "2";
    marketingDirectMailMe = "False";
    marketingEmailMe = "False";
    marketingPhoneMe = "False";
    marketingTextMe = "False";
    paperStatements = "False";
    paymentMethod = "";
    paymentMethodID = "8";
    promiseToPay = "False";
    promiseToPayDate = "";
    shareNonpublic = "False";
    showCSRFundingBankAccount = "False";
    targetID = "";
    verificationHistory = "False";
    verificationModeExpired = "False";
    verificationModeExpiredText = "";
    withdrawShow = "False";
}