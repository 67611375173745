<div *ngIf="!loading">
    <p class="mb-1 pl-3">Personal Info</p>
    <div class="row p-3">
      <div class="col-sm-6 col-md-4">
          <p class="clabel">First Name</p>
          <p class="cvalue">{{customerInfo.firstName}}</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <p class="clabel">Middle Name</p>
          <p class="cvalue">{{customerInfo.middleName}}</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <p class="clabel">Last Name</p>
          <p class="cvalue">{{customerInfo.lastName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Date of Birth</p>
          <p class="cvalue">{{customerInfo.dOB}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Email Address</p>
          <p class="cvalue">{{customerInfo.emailAddress}}</p>
      </div>
      <div class="col-sm-12">
          <p class="clabel">Address</p>
          <p class="cvalue"><span>{{customerInfo.address1}}</span><span class="ms-1">{{customerInfo.address2}}</span></p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">City</p>
          <p class="cvalue">{{customerInfo.city}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">State</p>
          <p class="cvalue">{{customerInfo.state}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Postal Code</p>
          <p class="cvalue">{{customerInfo.zipCode}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Country</p>
          <p class="cvalue">{{customerInfo.country}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Home Phone</p>
          <p class="cvalue">{{customerInfo.phone}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Cell Phone</p>
          <p class="cvalue">{{customerInfo.cellPhone}}</p>
      </div>
      <div class="col-sm-12">
          <p class="clabel">Social Security Numbe</p>
          <p class="cvalue">{{customerInfo.sSNMasked}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Reference 1</p>
          <p class="cvalue">{{customerInfo.reference1FirstName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <!-- <p class="clabel">First</p> -->
          <p class="cvalue">{{customerInfo.reference1LastName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Reference 2</p>
          <p class="cvalue">{{customerInfo.reference2FirstName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <!-- <p class="clabel">Reference 2</p> -->
          <p class="cvalue">{{customerInfo.reference2LastName}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <p class="mb-1 mt-2 p-3">Other</p>
    <div class="row p-3">
        <div class="col-sm-6">
            <p class="clabel">Location Id</p>
            <p class="cvalue">{{customerInfo.branchLocationID}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Location Name</p>
            <p class="cvalue">{{customerInfo.branchLocationName}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Patient Id</p>
            <p class="cvalue">{{customerInfo.leadPatientID}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Service Date</p>
            <p class="cvalue">{{customerInfo.serviceDate}}</p>
        </div>
      </div>
      <div class="p-3">
        <p>Payment Methods</p>
        <div *ngFor="let p of paymentMethods">
            <app-payment-method-info [p]="p"></app-payment-method-info>
        </div>
        <div *ngIf="!paymentMethods.length">
            <div class="alert alert-danger d-flex align-items-center cds-card" role="alert">
                <mat-icon class="bi flex-shrink-0 me-2">error_outline</mat-icon>
                <div>
                    You must have a primary and secondary payment method selected.
                </div>
              </div>
        </div>
      </div>
</div>



<!-- <div *ngIf="!loading">
    <p class="h6 mb-1">Personal Info</p>
    <div class="row">
      <div class="col-sm-6 col-md-4">
          <p class="clabel">First Name</p>
          <p class="cvalue">{{customerInfo.firstName}}</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <p class="clabel">Middle Name</p>
          <p class="cvalue">{{customerInfo.middleName}}</p>
      </div>
      <div class="col-sm-6 col-md-4">
          <p class="clabel">Last Name</p>
          <p class="cvalue">{{customerInfo.lastName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Date of Birth</p>
          <p class="cvalue">{{customerInfo.dOB}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Email Address</p>
          <p class="cvalue">{{customerInfo.emailAddress}}</p>
      </div>
      <div class="col-sm-12">
          <p class="clabel">Address</p>
          <p class="cvalue"><span>{{customerInfo.address1}}</span><span class="ms-1">{{customerInfo.address2}}</span></p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">City</p>
          <p class="cvalue">{{customerInfo.city}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">State</p>
          <p class="cvalue">{{customerInfo.state}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Postal Code</p>
          <p class="cvalue">{{customerInfo.zipCode}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Country</p>
          <p class="cvalue">{{customerInfo.country}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Home Phone</p>
          <p class="cvalue">{{customerInfo.phone}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Cell Phone</p>
          <p class="cvalue">{{customerInfo.cellPhone}}</p>
      </div>
      <div class="col-sm-12">
          <p class="clabel">Social Security Numbe</p>
          <p class="cvalue">{{customerInfo.sSNMasked}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Reference 1</p>
          <p class="cvalue">{{customerInfo.reference1FirstName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
         
          <p class="cvalue">{{customerInfo.reference1LastName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
          <p class="clabel">Reference 2</p>
          <p class="cvalue">{{customerInfo.reference2FirstName}}</p>
      </div>
      <div class="col-sm-12 col-md-6">
         
          <p class="cvalue">{{customerInfo.reference2LastName}}</p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <p class="h6 mb-1 mt-2">Other</p>
    <div class="row">
        <div class="col-sm-6">
            <p class="clabel">Location Id</p>
            <p class="cvalue">{{customerInfo.branchLocationID}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Location Name</p>
            <p class="cvalue">{{customerInfo.branchLocationName}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Patient Id</p>
            <p class="cvalue">{{customerInfo.leadPatientID}}</p>
        </div>
        <div class="col-sm-6">
            <p class="clabel">Service Date</p>
            <p class="cvalue">{{customerInfo.serviceDate}}</p>
        </div>
      </div>
      <div class="my-2">
        <p class="clabel">Payment Methods</p>
        <div *ngFor="let p of paymentMethods">
            <app-payment-method-info [p]="p"></app-payment-method-info>
        </div>
        <div *ngIf="!paymentMethods.length">
            <div class="alert alert-danger d-flex align-items-center" role="alert">
                <mat-icon class="bi flex-shrink-0 me-2">error_outline</mat-icon>
                <div>
                    You must have a primary and secondary payment method selected.
                </div>
              </div>
        </div>
      </div>
</div>
 -->
