import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LEGAL_STATUS_OPTIONS } from 'src/app/data/common-data';
import { ActivatedIds } from 'src/app/models/activated-ids';
import { LookupModel } from 'src/app/models/lookup-model';
import { TargetInfoModel } from 'src/app/models/target-info-model';
import { ApiService } from 'src/app/services/api.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmChangeData, ConfirmChangeModalComponent } from '../confirm-change-modal/confirm-change-modal.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit {
  ids: ActivatedIds = {};
  targetInfo: TargetInfoModel = new TargetInfoModel;
  legalStatusOptions: LookupModel[] = LEGAL_STATUS_OPTIONS;
  payMethods: LookupModel[] = []
  loanStatuses: LookupModel[] = [];
  popItems: string[] = [];
  constructor(private mainService: MainService,
    private dashboard: DashboardService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute, 
    private dialog: MatDialog,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.mainService.showHideCsrSidenav(true);
    this.mainService.activatedIds$.subscribe(ids => {
      this.ids = ids;
    });
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.mainService.updateActivatedIdsMany([
        { propname: 'campaignGuid', propValue: paramMap.get('campaignGuid')?.toString() },
        { propname: 'targetGuid', propValue: paramMap.get('targetGuid')?.toString() },
        { propname: 'customerGuid', propValue: paramMap.get('customerGuid')?.toString() },
      ]);
      this.getTargetInfo();
      this.getLookupData();
    });
  }

  getTargetInfo() {
    if (this.ids.customerGuid && this.ids.campaignGuid && this.ids.targetGuid){
      this.apiService.get(`csr/campaign-target-info/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`)
      .subscribe((res: any) => {
        this.targetInfo = res;
        this.dashboard.setTargetInfo(this.targetInfo);
        this.setPopOvers();
        this.setLegalStatusOptions();
      },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }

  getLookupData() {
    if (this.ids.customerGuid){
      this.apiService.get(`csr/lookup-data/${this.ids.customerGuid}`)
      .subscribe((res: any) => {
        this.payMethods = res.payMethods;
        this.loanStatuses = res.loanStatuses;
        this.dashboard.setLookup(res);
      },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }

  hasFeature(feature: string, mode: string = 'write') {
    switch (mode) {
      case 'read':
        return this.userService.hasRead(feature);
        break;
      case 'feature': 
        return this.userService.hasFeature(feature);
        break;
      default:
        return this.userService.hasWrite(feature);
        break;
    }
  }

  concessionChange(){
    var msg = this.targetInfo.isConcession  == 'True'
    ? 'Are you sure you want to remove the Concession for this loan?'
    : 'Are you sure you want to set this loan as Concession?';
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '480px',
      data: this.getStatusChangeDataModel(null, 'Concession Confirmation', msg),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
         this.updateConcession();
      }
    });
  }

  withdrawShow(){
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '480px',
      data: this.getStatusChangeDataModel(null, 'Withdraw Loan Confirmation', 'Are you sure you want to Withdraw this loan?'),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
        this.withdrawLoan();
      }
    });
  }

  cancelShow(){
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(null, 'Cancel Loan Confirmation', 'Are you sure you want to Cancel this loan?'),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirmed){
        this.cancelLoan();
      }
    });
  }

  payStatusChange(opt: LookupModel): void {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Pay Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updatePaymentMethod(result.comment, opt);
      }
    });
  }

  legalStatusChange(opt: LookupModel): void {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Legal Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updateLegalStatus(result.comment, opt);
      }
    });
  }

  cantractStatusChange(opt: LookupModel): void {
    const dialogRef = this.dialog.open(ConfirmChangeModalComponent, {
      width: '450px',
      data: this.getStatusChangeDataModel(opt, 'Contract Status Change Confirmation', null, true),
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.comment){
        this.updateContractStatus(result.comment, opt);
      }
    });
  }

  updatePaymentMethod(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'PAY METHOD STATUS',
      OldValue: this.targetInfo.paymentMethodID,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.paymentMethod = opt.desc;
        this.targetInfo.paymentMethodID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Pay method successfully updated')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  

  cancelLoan(){
    this.mainService.showLoading();
    this.apiService.post(`csr/cancel-loan/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`, null)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.mainService.showSuccessModal('Good Job!', 'Loan Cancel Successfull');
        this.getTargetInfo();
      }else{
        this.mainService.showSuccessModal('Ops!', 'Loan Cancel not available');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Loan Cancel not available');
      }
    );
  }

  withdrawLoan(){
    this.mainService.showLoading();
    this.apiService.post(`csr/withdraw-loan/${this.ids.customerGuid}/${this.ids.campaignGuid}/${this.ids.targetGuid}`, null)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.mainService.showSuccessModal('Good Job!', 'Loan Withdraw Successfull');
        this.getTargetInfo();
      }else{
        this.mainService.showSuccessModal('Ops!', 'Loan Withdraw not available');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Loan Withdraw not available');
      }
    );
  }

  updateLegalStatus(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'LEGAL FLAG',
      OldValue: this.targetInfo.legalStatusID,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.legalStatus = opt.desc;
        this.targetInfo.legalStatusID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Legal Status updated')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  updateContractStatus(comment: string, opt: LookupModel){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      FieldName: 'LOAN STATUS',
      OldValue: this.targetInfo.loanStatusID,
      NewValue: opt.id,
      Comments: comment
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/update-field', json)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.loanStatus = opt.desc;
        this.targetInfo.loanStatusID = opt.id;
        this.mainService.showSuccessModal('Good Job!', 'Contract Status Updated.')
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
      }
    );
  }

  updateConcession(){
    this.mainService.showLoading();
    var obj = {
      TargetGuid: this.ids.targetGuid,
      CampaignGuid: this.ids.campaignGuid,
      CustomerGuid: this.ids.customerGuid,
      IsConcession: this.targetInfo.isConcession == 'True'
    }
    var json = JSON.stringify(obj);
    this.apiService.post('csr/set-concession', json)
    .subscribe((res: any) => {
      this.mainService.dismissLoading();
      if (res){
        this.targetInfo.isConcession = this.targetInfo.isConcession == 'True' ? 'False' : 'True';
        this.mainService.showSuccessModal('Good Job!', 'Concession updated');
      }
    },
      (err: any) => {
        console.error(err);
        this.mainService.dismissLoading();
        this.mainService.showSuccessModal('Ops!', 'Error Occured');
      }
    );
  }

  setLegalStatusOptions(){
    this.legalStatusOptions = [];
    LEGAL_STATUS_OPTIONS.forEach(opt => {
      if (opt.desc.trim().toLocaleLowerCase() !== this.targetInfo.legalStatus.trim().toLocaleLowerCase()){
        this.legalStatusOptions.push(opt);
      }
    });
  }

  setPopOvers(){
    this.popItems = [];
    this.popItems.push(`Loan Number: ${this.targetInfo.loanNumber}`);
    this.popItems.push(`TargetLoanGUID: ${this.ids.targetGuid}`);
    this.popItems.push(`Campaign ID: ${this.targetInfo.campaignID}`);
    this.popItems.push(`Target ID: ${this.targetInfo.targetID}`);
    this.popItems.push(`NLSAcctRefNo: ${this.targetInfo.acctrefno}`);
    this.popItems.push(`Invitation Code: ${this.targetInfo.invitationCode}`);
  }

  ngOnDestroy(){
    this.dashboard.setTargetInfo(new TargetInfoModel);
  }

  private getStatusChangeDataModel(opt: LookupModel | null | undefined, title: any, subTitle: any = null, needComment = false){
    var data: ConfirmChangeData = {
      opt : opt,
      title: title,
      subTitle: subTitle,
      needComment : needComment
    }
    return data;
  }

}
