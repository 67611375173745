<app-dashboard-header></app-dashboard-header>
<div class="row">
    <div class="col-md-12 col-lg-4">
        <div class="cds-card">

            <div class="p-3" >
              <div class="d-flex">
                  <div class="p-1 flex-grow-1">
                      <p class="h4 ms-2 m-0">Customer Info</p>
                  </div>
                  <div class="p-1" *ngIf="hasFeature('CSR_DELETE_CUSTOMER')" >
                      <button mat-flat-button color="warn" class="covered-btn" (click)="deleteCustomerInfo()">Delete</button>
                  </div>
                  <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_CONTACT') && !showEditCusomer">
                      <button mat-flat-button color="primary" class="covered-btn" (click)="editCustomerInfo()">Edit</button>
                  </div>
                  <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_CONTACT') && showEditCusomer">
                      <button mat-flat-button class="covered-btn surface-600 text-white" (click)="cancelEditCustomerInfo()">Cancel</button>
                  </div>
                  <div class="p-1" *ngIf="hasFeature('CSR_CUSTOMER_DASHBOARD_EDIT_CONTACT') && showEditCusomer">
                      <button mat-flat-button color="primary" class="covered-btn" (click)="saveCustomerInfo()">Save</button>
                  </div>
                </div>
                <mat-divider></mat-divider>
            </div>
            <mat-card-content class="p-2 mb-0">
                <app-customer-info *ngIf="!showEditCusomer"></app-customer-info>
                <app-customer-info-edit *ngIf="showEditCusomer" (updated)="customerUpdated($event)"></app-customer-info-edit>
            </mat-card-content>
      </div>
    </div>
    <div class="col-md-12 col-lg-4">
        <app-add-customer-notification></app-add-customer-notification>
        <app-customer-contact></app-customer-contact>
    </div>
    <div *ngIf="hasFeature('CSR_CUSTOMER_NOTE_LIST', 'feature')" class="col-md-12 col-lg-4">
        <app-customer-notes></app-customer-notes>
    </div>
</div>
