export class TargetMessageModel {
    attachmentList = "";
    attachmentScanResultList = "";
    attachmentTitleList = "";
    deliveryStatus = "";
    emailStatus = "";
    fileDescription = "";
    fileGuid = "";
    fileName = "";
    from = "";
    fromEmail = "";
    fromEmailOrNumber = "";
    fromFullName = "";
    isDraft = "";
    marketingTextMe = "";
    messageGUID = "";
    messageReplyLevel = "";
    messageSubject = " "
    messageType = "";
    messageText = "";
    messageTypeID = "";
    msgGuidFolder = "";
    needToAttachTarget = "";
    objectBody = "";
    replied = "";
    replyToMessageID = "";
    rowCount = "";
    rowNumber = "";
    sBTCallbackGUID = "";
    sMSDownloadErrorCode = "";
    sendReceivedDate = "";
    senderFirstName = "";
    senderLastName = "";
    tTPL = "";
    textMeAccountInfo = "";
    toEmailOrNumber = "";
    wasRead = "";
    attachmentUrl = "";
}