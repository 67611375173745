import { PaymentMethodModel } from "./payment-methods";

export class CourtesyAdjustmentInfo {
    accountAdjustmentBlocked: boolean = false;
    bankAccountAdjustmentBlocked: boolean = false;
    cardAdjustmentBlocked: boolean = false;
    accountAdjustmentBlockedMessage: string = '';
    bankAccountAdjustmentBlockedMessage: string = '';
    cardAdjustmentBlockedMessage: string = '';
    paymentMethods: PaymentMethodModel[] = [];
}