import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { NoteModel } from 'src/app/models/note-model';

@Component({
  selector: 'app-note-list-page',
  templateUrl: './note-list-page.component.html',
  styleUrls: ['./note-list-page.component.scss']
})
export class NoteListPageComponent implements OnInit {
  notes: NoteModel[] = [];
  customerGuid: string | undefined = '';
  loading = true;
  first: number = 0;
  
  searchParams: any = {};
  GridTitle: string = "Notes List"; 
   displayedColumns: string[] = ['createDate', 'loanNumber', 'customerFullName', 'noteCategoryDescription', 'note', 'userFullName'];
  constructor(private main: MainService, 
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.customerGuid = paramMap.get('customerGuid')?.toString();
      this.getNotes();
    });
    this.main.showHideCsrSidenav(false);
  }

  getNotes(){
    this.apiService.get(`csr/customer-notes/${this.customerGuid}`)
    .subscribe((res: any) => {
      this.notes = res || [];
      this.loading = false;
    },
      (err: any) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

}